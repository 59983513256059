// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.whiteCard {
  width: 100%;
  height: 706px;
  display: flexbox;
  justify-content: center;
}

.darkCard {
  background-color: #505050 !important;
  width: 100%;
  height: 706px;
  display: flexbox;
  justify-content: center;
}

.lightCard {
  background-color: #B4B4B4 !important;
  width: 100%;
  height: 706px;
  display: flexbox;
  justify-content: center;
}

.title {
  color: #505050 !important;
  font-size: 74px !important;
  width: 759px;
  text-align: left;
  margin-left: 134px;
}

.light-title {
  color: #FFF !important;
  font-size: 74px !important;
  width: 759px;
  text-align: left;
  margin-left: 134px;
}

.section-2 {
  margin-top: -102px;
}`, "",{"version":3,"sources":["webpack://./src/pages/styles/HomePage.scss"],"names":[],"mappings":"AAAA;EACK,WAAA;EACA,aAAA;EACA,gBAAA;EACA,uBAAA;AACL;;AAKA;EACI,oCAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;EACA,uBAAA;AAFJ;;AAMA;EACI,oCAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;EACA,uBAAA;AAHJ;;AAMA;EACI,yBAAA;EACC,0BAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AAHL;;AAMA;EACI,sBAAA;EACC,0BAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AAHL;;AAMA;EACI,kBAAA;AAHJ","sourcesContent":[".whiteCard{\n     width: 100%;   \n     height: 706px;\n     display: flexbox;\n     justify-content: center;\n     \n}\n// .pull-up{\n//     margin-top: -102px;\n// }\n.darkCard{\n    background-color: #505050 !important;\n    width: 100%;   \n    height: 706px;\n    display: flexbox;\n    justify-content: center;\n    \n}\n\n.lightCard{\n    background-color: #B4B4B4 !important;\n    width: 100%;   \n    height: 706px;\n    display: flexbox;\n    justify-content: center;\n}\n\n.title{\n    color: #505050 !important;\n     font-size: 74px !important;\n     width: 759px; \n     text-align: left;\n     margin-left: 134px;;\n}\n\n.light-title{\n    color: #FFF !important;\n     font-size: 74px !important;\n     width: 759px; \n     text-align: left;\n     margin-left: 134px;;\n}\n\n.section-2{\n    margin-top: -102px;\n}\n// @media screen and (max-width: 1400px) {\n// \t.pull-up{\n//         margin-top: -77px;\n//     }\n// }\n// @media screen and (max-width: 1200px) {\n// \t.pull-up{\n//         margin-top: -223px;\n//     }\n// }\n// @media screen and (max-width: 992px) {\n// \t.pull-up{\n//         margin-top: -100px;\n//     }\n// }\n\n// @media screen and (max-width: 768px) {\n// \t.pull-up{\n//         margin-top: -126px;\n//     }\n// }\n// @media screen and (max-width: 576px) {\n// \t.pull-up{\n//         margin-top: -145px;\n//     }\n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
