// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll-container {
  margin: 30px;
  height: 550px;
  width: 100%;
}

.Header {
  font-size: var(--size-6);
  color: var(--main-2);
}

.scroll-container li {
  padding: 0.3rem 1rem;
}

.scroll-container li.Header {
  background-color: white;
  cursor: pointer;
  display: block;
  font-weight: bold;
  padding: 0.5rem;
  width: 100%;
}

.list-sub-heading {
  color: var(--main-2);
  font-size: var(--size-4);
}

.list-heading {
  color: var(--main-2);
  font-size: var(--size-2);
  font-weight: 800;
  line-height: 1;
}

.list-text {
  color: var(--main-2);
  font-size: var(--size-8);
  padding: 55px 0px;
}

@media screen and (max-width: 992px) {
  .scroll-container {
    margin: auto;
  }
  .list-sub-heading {
    font-size: var(--size-8);
  }
  .list-heading {
    font-size: var(--size-4);
  }
  .list-text {
    font-size: var(--size-10);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/content-blocks/HomePageSection4.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,WAAA;AACF;;AACA;EACE,wBAAA;EACA,oBAAA;AAEF;;AAAA;EACE,oBAAA;AAGF;;AAGA;EACE,uBAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;EACA,eAAA;EACA,WAAA;AAAF;;AAEA;EACE,oBAAA;EACA,wBAAA;AACF;;AACA;EACE,oBAAA;EACA,wBAAA;EACA,gBAAA;EACA,cAAA;AAEF;;AAAA;EACE,oBAAA;EACA,wBAAA;EACA,iBAAA;AAGF;;AAAA;EACE;IACE,YAAA;EAGF;EAAA;IACE,wBAAA;EAEF;EACA;IACE,wBAAA;EACF;EAEA;IACE,yBAAA;EAAF;AACF","sourcesContent":[".scroll-container {\n  margin: 30px;\n  height: 550px;\n  width: 100%;\n}\n.Header{\n  font-size: var(--size-6);\n  color: var(--main-2);\n}\n.scroll-container li {\n  padding: 0.3rem 1rem;\n}\n\n.scroll-container .Header {\n}\n\n.scroll-container li.Header {\n  background-color: white;\n  cursor: pointer;\n  display: block;\n  font-weight: bold;\n  padding: 0.5rem;\n  width: 100%;\n}\n.list-sub-heading{\n  color: var(--main-2);\n  font-size: var(--size-4)\n}\n.list-heading{\n  color: var(--main-2);\n  font-size: var(--size-2);\n  font-weight: 800;\n  line-height: 1;\n}\n.list-text{\n  color: var(--main-2);\n  font-size: var(--size-8);\n  padding: 55px 0px;\n}\n\n@media screen and (max-width: 992px) {\n  .scroll-container {\n    margin: auto;\n  }\n\n  .list-sub-heading{\n    font-size: var(--size-8)\n  }\n\n  .list-heading{\n    font-size: var(--size-4);\n  }\n\n  .list-text{\n    font-size: var(--size-10);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
