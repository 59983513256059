import React, { Fragment} from "react";
import Button from "../content-types/Button";
import Image from "../content-types/image";

import "./AboutPageSection3.scss";

const AboutPageSection3 = ({ data }) => {
	return (
		<Fragment>
			<div className="back-sub-1">
				<div className="container p-2 p-lg-5">
					<div className="row my-5">
						<div className="col-12 col-lg-6 p-4 is-desktop">
							{data?.attributes?.ContentTypes?.map((contentType, index) => {
								if (contentType?.__component === "content-type.media") {
									return (
										<Fragment key={index}>
											<Image data={contentType} />
										</Fragment>
									);
								}
								return false
							})}
						</div>
						<div className="col-12 col-lg-6 my-auto px-5 px-lg-4">
							{data?.attributes?.ContentTypes?.map((contentType, index) => {
								if (contentType?.__component === "content-type.title") {
									return (
										<Fragment key={index}>
											<div
												className="section-heading font-bold front-main-2"
												dangerouslySetInnerHTML={{
													__html: contentType?.Heading?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
											<div
												className="front-main-2 font-bold section-text mb-3"
												dangerouslySetInnerHTML={{
													__html: contentType?.SubHeading?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
										</Fragment>
									);
								}
								if (contentType?.__component === "content-type.list") {
									return contentType?.Item.map((item, index) => {
										return (
											<Fragment key={index}>
												<ul className="front-main-2 m-0 ">
													<li>
														<div className="ps-2 section-text">
															{item?.Text}
														</div>
													</li>
												</ul>
											</Fragment>
										);
									});
								}
								if (contentType?.__component === "content-type.text") {
									return (
										<Fragment key={index}>
											<div
												className="front-main-2 my-3 section-text"
												dangerouslySetInnerHTML={{
													__html: contentType?.Text?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
										</Fragment>
									);
								}
								if (contentType?.__component === "content-type.buttons") {
									return (
										<Fragment key={index}>
											<Button data={contentType} />
										</Fragment>
									);
								}
								return false
							})}
						</div>
						<div className="col-12 col-lg-6 p-4 is-mobile">
							{data?.attributes?.ContentTypes?.map((contentType, index) => {
								if (contentType?.__component === "content-type.media") {
									return (
										<Fragment key={index}>
											<Image data={contentType} />
										</Fragment>
									);
								}
								return false
							})}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default AboutPageSection3;
