import React, { Fragment, useEffect, useState } from "react";

const Accordion = ({ number, title, text }) => {
	const [open, setOpen] = useState(false);

	return (
		<Fragment>
			<div className="py-4">
				<div onClick={() => setOpen(!open)} style={{ cursor: "pointer" }}>
					{number && (
						<div
							className="front-main-2 font-size-6 font-bold py-2"
							style={{ borderBottom: "1px solid" }}
						>
							{number}{" "}
							<span style={{ float: "right" }}>{open ? "-" : "+"}</span>
						</div>
					)}
					{title && (
						<div
							className="front-main-2 font-size-6 font-bold py-3"
							dangerouslySetInnerHTML={{
								__html: title?.replace(/(?:\r\n|\r|\n)/g, "<br>"),
							}}
						></div>
					)}
				</div>
				{text && open && (
					<div
						className="front-sub-7 font-size-8"
						dangerouslySetInnerHTML={{
							__html: text?.replace(/(?:\r\n|\r|\n)/g, "<br>"),
						}}
					></div>
				)}
			</div>
		</Fragment>
	);
};

export default Accordion;
