import React, { Fragment } from "react";
import "./AboutPageSection4.scss";

const AboutPageSection4 = ({ data }) => {
	const fadeIn = document.querySelectorAll(".fade-in");
	const fadeOut = document.querySelectorAll(".fade-out");

	const appearOptions = {
		threshhold: 1,
		rootMargin: "0px 0px -810px 0px",
	};

	const removeOptions = {
		threshhold: 1,
		rootMargin: "0px 0px -810px 0px",
	};

	const appearOnScroll = new IntersectionObserver(function (
		entries,
		appearOnScroll
	) {
		entries.forEach((entry) => {
			if (!entry.isIntersecting) {
				return;
			} else {
				entry.target.classList.add("appear");
				appearOnScroll.unobserve(entry.target);
			}
		});
	},
	appearOptions);

	const removeOnScroll = new IntersectionObserver(function (
		entries,
		removeOnScroll
	) {
		entries.forEach((entry) => {
			if (!entry.isIntersecting) {
				return;
			} else {
				entry.target.classList.add("remove");
				removeOnScroll.unobserve(entry.target);
			}
		});
	},
	removeOptions);

	fadeIn.forEach((faders) => {
		appearOnScroll.observe(faders);
	});
	fadeOut.forEach((faders) => {
		removeOnScroll.observe(faders);
	});

	return (
		<Fragment>
			<div className="back-main-1">
				<div className="container mh-810">
					<div className="row h-100 fade-out">
						<div className="col-12 my-auto">
							{data?.attributes?.ContentTypes?.map((contentType, index) => {
								if (contentType?.__component === "content-type.title") {
									return (
										<Fragment key={index}>
											<div
												className="font-size-2 font-bold front-sub-1 text-center"
												dangerouslySetInnerHTML={{
													__html: contentType?.Heading?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
											<div
												className="front-sub-1 font-regular font-size-8 mb-3 text-center"
												dangerouslySetInnerHTML={{
													__html: contentType?.SubHeading?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
										</Fragment>
									);
								}
								return false
							})}
						</div>
					</div>
					<div className="row section-2 h-100 fade-in mx-auto">
						{data?.attributes?.ContentTypes?.map((contentType, index) => {
							if (contentType?.__component === "content-type.list") {
								return (
									<Fragment key={index}>
										<div className="col-12 col-lg-4">
											<img
												className="w-100 image-1"
												src={`${contentType?.Item[0].Icon?.data?.attributes?.url}`}
												alt={contentType?.Item[0]?.title}
											/>
										</div>
										<div className="col-12 col-lg-4">
											<img
												className="w-100 image-2"
												src={`${contentType?.Item[1].Icon?.data?.attributes?.url}`}
												alt={contentType?.Item[1]?.title}
											/>
										</div>
										<div className="col-12 col-lg-4">
											<img
												className="w-100 image-3"
												src={`${contentType?.Item[2].Icon?.data?.attributes?.url}`}
												alt={contentType?.Item[2]?.title}
											/>
										</div>
									</Fragment>
								);
							}
							return false
						})}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default AboutPageSection4;
