import React, { Fragment } from 'react';

const NotFoundPage = () => {

    return (
        <Fragment>
            404 Page Not Found
        </Fragment>
    );
};

export default NotFoundPage;