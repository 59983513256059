import React, { Fragment } from "react";
import "./styles/HomePage.scss";
import Banner from "../components/content-blocks/Banner";
import HomePageSection2 from "../components/content-blocks/HomePageSection2";
import HomePageSection3 from "../components/content-blocks/HomePageSection3";
import HomePageSection4 from "../components/content-blocks/HomePageSection4";
import HomePageSection5 from "../components/content-blocks/HomePageSection5";
import HomePageSection6 from "../components/content-blocks/HomePageSection6";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import SEO from "../components/content-types/SEO";

const HomePage = ({ data, notificationMessage }) => {
	return (
		<Fragment>
			{data?.SEOList?.SEOItem?.map((contentBlock, index) => {
					return <SEO key={index} data={contentBlock} />
				})}
			{notificationMessage !== "" && <div className="notification-message-container">
				<div className="notification-box">
					<span className="notification-message">{notificationMessage}</span>
				</div>
			</div>}
			{data?.ContentBlocks?.data?.map((contentBlock, index) => {
				if (contentBlock?.attributes?.Type === "Banner") {
					return <Banner key={index} data={contentBlock} />;
				}
				return false;
			})}
			<div className="pull-up">
				{data?.ContentBlocks?.data?.map((contentBlock, index) => {
					if (contentBlock?.attributes?.Type === "Section2") {
						return <HomePageSection2 key={index} data={contentBlock} />;
					}
					if (contentBlock?.attributes?.Type === "Section3") {
						return <HomePageSection3 key={index} data={contentBlock} />;
					}
					if (contentBlock?.attributes?.Type === "Section4") {
						return <HomePageSection4 key={index} data={contentBlock} />;
					}
					if (contentBlock?.attributes?.Type === "Section5") {
						return <HomePageSection5 key={index} data={contentBlock} />;
					}
					if (contentBlock?.attributes?.Type === "Section6") {
						return <HomePageSection6 key={index} data={contentBlock} />;
					}
					return false;
				})}
			</div>
		</Fragment>
	);
};

export default HomePage;
