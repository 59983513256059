// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pricing-section-3-img {
  margin-top: -180px;
}

.pricing-heading {
  font-size: var(--size-2);
  text-align: left;
}

@media screen and (max-width: 992px) {
  .pricing-section-3-img {
    margin-top: 10px;
    width: 70%;
  }
  .pricing-heading {
    font-size: var(--size-5);
    text-align: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/content-blocks/PricingPageSection3.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;AACD;;AAEA;EACC,wBAAA;EACA,gBAAA;AACD;;AAEA;EACC;IACC,gBAAA;IACA,UAAA;EACA;EACD;IACC,wBAAA;IACA,kBAAA;EACA;AACF","sourcesContent":[".pricing-section-3-img{\n\tmargin-top: -180px;\n}\n\n.pricing-heading{\n\tfont-size: var(--size-2);\n\ttext-align: left;\n}\n\n@media screen and (max-width: 992px) {\n\t.pricing-section-3-img{\n\t\tmargin-top: 10px;\n\t\twidth: 70%;\n\t}\n\t.pricing-heading{\n\t\tfont-size: var(--size-5);\n\t\ttext-align: center;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
