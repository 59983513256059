// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-image {
  position: absolute;
  z-index: 999;
  width: 100%;
  bottom: 0;
}

.video {
  width: 100%;
  height: 800px;
}

@media screen and (max-width: 1400px) {
  .banner-image {
    top: 0px;
    margin-top: -4px;
  }
  .video {
    width: 100%;
    height: 700px;
  }
}
@media screen and (max-width: 1200px) {
  .banner-image {
    top: 0px;
    margin-top: -32px;
  }
}
@media screen and (max-width: 992px) {
  .banner-image {
    top: 0px;
    margin-top: 14px;
  }
  .video {
    width: 100%;
    height: 600px;
  }
}
@media screen and (max-width: 768px) {
  .banner-image {
    top: 0px;
    position: relative;
    margin-top: -80px;
  }
  .video {
    width: 100%;
    height: 400px;
  }
}
@media screen and (max-width: 576px) {
  .banner-image {
    position: relative;
    margin-top: 0px;
    padding: 0px;
  }
  .video {
    width: 100%;
    height: 250px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/content-types/Media.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;AACD;;AAEA;EACC,WAAA;EACA,aAAA;AACD;;AAEA;EACC;IACC,QAAA;IACA,gBAAA;EACA;EAED;IACC,WAAA;IACA,aAAA;EAAA;AACF;AAGA;EACC;IACC,QAAA;IACA,iBAAA;EADA;AACF;AAKA;EACC;IACC,QAAA;IACA,gBAAA;EAHA;EAKD;IACC,WAAA;IACA,aAAA;EAHA;AACF;AAMA;EACC;IACC,QAAA;IACA,kBAAA;IACA,iBAAA;EAJA;EAMD;IACC,WAAA;IACA,aAAA;EAJA;AACF;AAOA;EACC;IACC,kBAAA;IACA,eAAA;IACA,YAAA;EALA;EAOD;IACC,WAAA;IACA,aAAA;EALA;AACF","sourcesContent":[".banner-image{\n\tposition: absolute;\n\tz-index: 999;\n\twidth: 100%;\n\tbottom: 0;\n}\n\n.video{\n\twidth: 100%;\n\theight: 800px;\n}\n\n@media screen and (max-width: 1400px) {\n\t.banner-image{\n\t\ttop: 0px;\n\t\tmargin-top: -4px;\n\t}\n\n\t.video{\n\t\twidth: 100%;\n\t\theight: 700px;\n\t}\n}\n\n@media screen and (max-width: 1200px) {\n\t.banner-image{\n\t\ttop: 0px;\n\t\tmargin-top: -32px;\n\t}\n\n}\n\n@media screen and (max-width: 992px){\n\t.banner-image {\n\t\ttop: 0px;\n\t\tmargin-top: 14px;\n\t}\n\t.video{\n\t\twidth: 100%;\n\t\theight: 600px;\n\t}\n}\n\n@media screen and (max-width: 768px){\n\t.banner-image{\n\t\ttop: 0px;\n\t\tposition: relative;\n\t\tmargin-top: -80px;\n\t}\n\t.video{\n\t\twidth: 100%;\n\t\theight: 400px;\n\t}\n}\n\n@media screen and (max-width: 576px) {\n\t.banner-image{\n\t\tposition: relative;\n\t\tmargin-top: 0px;\n\t\tpadding: 0px;\n\t}\n\t.video{\n\t\twidth: 100%;\n\t\theight: 250px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
