import React, { Fragment } from "react";
import logo from "../assets/images/glassFooterLogo.png";
import facebook from "../assets/images/facebook.png";
import twitter from "../assets/images/twitter.png";
import instagram from "../assets/images/instagram.png";
import "./styles/Footer.scss";
import { Link } from "react-router-dom";
import ScrollToTop from "./ScrollToTopOnMount";

const Footer = () => {
	return (
		<Fragment>
			<div className="footer-container back-main-1">
				<div className="container">
					<div className="row py-5 px-5 px-lg-0">
						<div className="col-12 col-lg-3 mb-5">
							<img alt="glass-logo" width={154.8} height={60} src={logo} />
						</div>
						<div className="col-12 col-lg-3">
							<ul className="footer-links">
							<ScrollToTop>
								<Link as={Link} to="/">
									<li className="footer-links pb-3">Home</li>
								</Link>
								<Link as={Link} to="/about">
									<li className="footer-links pb-3">How Glass Works</li>
								</Link>
								<Link as={Link} to="/pricing">
									<li className="footer-links pb-3">Pricing</li>
								</Link>
								<Link as={Link} to="/help">
									<li className="footer-links pb-3">FAQs</li>
								</Link>
								<Link as={Link} to="/contact-us">
									<li className="footer-links pb-3">Contact Us</li>
								</Link>
								</ScrollToTop>
							</ul>
						</div>
						<div className="col-12 col-lg-3">
						{/* <b className="pb-3 d-block front-sub-1 font-size-9">Contact Us</b> */}
						<p className="front-sub-1 font-size-9">
								<a className="front-sub-1" target="blank" href="https://goo.gl/maps/a7mPqPiPqdLqF5qg8">Unit 2 - 44 Saturn Crescent, <br /> Linbro Business Park, <br />Sandton, 2065</a>
								<br />
								<br />
								<a className="front-sub-1" href="tel:0872606600">087 260 6600</a>
								<br />
								<br />
								<a className="front-sub-1" href="mailto:support@dashpayglass.co.za">Support@dashpayglass.co.za</a>
							</p>
						</div>
						<div className="col-12 col-lg-3 position-relative">
						<div className="front-sub-1 font-size-9 d-grid">
								<ScrollToTop>
								<Link
										as={Link}
										to="/articles"
										className="font-bold front-sub-1 text-decoration-none pb-3"
									>
										Blogs
									</Link>
									<Link
										as={Link}
										to="/terms-and-conditions"
										className="font-bold front-sub-1 text-decoration-none pb-3"
									>
										Terms and Conditions
									</Link>
									<Link
										as={Link}
										to="/privacy-policy"
										className="font-bold front-sub-1 text-decoration-none"
									>
										Privacy Policy
									</Link>
								</ScrollToTop>
							</div>
							<div className="social-icons mt-5 mt-lg-0">
								<a href="https://www.facebook.com/Dashpayglass/" target="blank">
								<img
									className="facebook-logo pe-2"
									alt="facebook-logo"
									height={31}
									src={facebook}
								/>
								</a>
								<a href="https://www.instagram.com/dashpayglass/" target="blank">
								<img
									className="instagram-logo px-2"
									alt="instagram-logo"
									height={31}
									src={instagram}
								/>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className="container-fluid px-0 back-main-4">
					<p className="front-sub-1 font-size-9 text-center py-4 m-0">
						© {(new Date().getFullYear())} Glass, All Rights Reserved
					</p>
				</div>
			</div>
		</Fragment>
	);
};

export default Footer;
