import React, { Fragment, useState } from "react";
import { Routes, Route } from "react-router-dom";
import NotFoundPage from "../pages/NotFoundPage";
import HomePage from "../pages/HomePage";
import AboutPage from "../pages/AboutPage";
import PricingPage from "../pages/PricingPage";
import HelpPage from "../pages/HelpPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import TermsAndConditionsPage from "../pages/TermsAndConditionsPage";
import ArticlesPage from "../pages/ArticlesPage";
import ArticleIdPage from "../pages/ArticleIdPage";
import ContactPage from "../pages/ContactPage";

const MainRoutes = ({ data, recaptchaKey }) => {
	const [notificationMessage, setNotificationMessage] = useState("");

	return (
		<Fragment>
			<Routes className="route-content">
				<Route path="*" element={<NotFoundPage />} />
				<Route
					exact
					path="/"
					element={
						<HomePage
							notificationMessage={notificationMessage}
							data={data.find((item) => item.attributes.Name === "Home")?.attributes}
						/>
					}
				></Route>
				<Route
					exact
					path="/about"
					element={
						<AboutPage
							data={data.find((item) => item.attributes.Name === "How Glass Works")?.attributes}
						/>
					}
				></Route>
				<Route
					exact
					path="/pricing"
					element={
						<PricingPage
							data={data.find((item) => item.attributes.Name === "Pricing")?.attributes}
						/>
					}
				></Route>
				<Route
					exact
					path="/help"
					element={
						<HelpPage
							data={data.find((item) => item.attributes.Name === "Help and FAQs")?.attributes}
						/>
					}
				></Route>
				<Route
					exact
					path="/terms-and-conditions"
					element={
						<TermsAndConditionsPage
							data={data.find((item) => item.attributes.Name === "Terms and Conditions")?.attributes}
						/>
					}
				></Route>
				<Route
					exact
					path="/privacy-policy"
					element={
						<PrivacyPolicyPage
							data={data.find((item) => item.attributes.Name === "Privacy Policy")?.attributes}
						/>
					}
				></Route>
				<Route
					exact
					path="/articles"
					element={
						<ArticlesPage 
							banner={data.find((item) => item.attributes.Name === "Blog")?.attributes}
						/>
					}
				>
				</Route>
				<Route
					exact
					path="/article"
				>
					<Route
						exact
						path=":id" element={<ArticleIdPage />}
					/>
				</Route>
				<Route
					exact
					path="/contact-us"
					element={
						<ContactPage
							setNotificationMessage={setNotificationMessage}
							recaptchaKey={recaptchaKey}
							data={data.find((item) => item.attributes.Name === "Contact")?.attributes}
						/>
					}
				></Route>
			</Routes>
		</Fragment>
	);
};
export default MainRoutes;
