import React, { Fragment } from "react";
import Banner from "../components/content-blocks/Banner";
import "./styles/AboutPage.scss";
import AboutPageSection2 from "../components/content-blocks/AboutPageSection2";
import AboutPageSection3 from "../components/content-blocks/AboutPageSection3";
import AboutPageSection5 from "../components/content-blocks/AboutPageSection5";
import AboutPageSection4 from "../components/content-blocks/AboutPageSection4";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import SEO from "../components/content-types/SEO";

const AboutPage = ({ data }) => {
	return (
		<Fragment>
			{data?.SEOList?.SEOItem?.map((contentBlock, index) => {
					return <SEO key={index} data={contentBlock} />
				})}

			{data?.ContentBlocks?.data?.map((contentBlock, index) => {
				if (contentBlock?.attributes?.Type === "Banner") {
					return <Banner key={index} data={contentBlock} />;
				}
				if (contentBlock?.attributes?.Type === "Section2") {
					return <AboutPageSection2 key={index} data={contentBlock} />;
				}
				if (contentBlock?.attributes?.Type === "Section3") {
					return <AboutPageSection3 key={index} data={contentBlock} />;
				}
				if (contentBlock?.attributes?.Type === "Section4") {
					return <AboutPageSection4 key={index} data={contentBlock} />;
				}
				if (contentBlock?.attributes?.Type === "Section5") {
					return <AboutPageSection5 key={index} data={contentBlock} />;
				}
				return false;
			})}
		</Fragment>
	);
};

export default AboutPage;
