import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import "./Button.scss";

const Button = ({ data }) => {
	if (data?.Text === "Google") {
		return (
			<Fragment>
				<a href={`https://${data?.URL}`} target="blank">
					<button className="button-g btn-google">
						<span className="btn-title">Google Play</span>
					</button>
				</a>
			</Fragment>
		);
	}
	if (data?.Text === "Huawei") {
		return (
			<Fragment>
				<a href={`https://${data?.URL}`} target="blank">
					<button className="button-g btn-huawei">
						<span className="btn-title">AppGallery</span>
					</button>
				</a>
			</Fragment>
		);
	}
	if (data?.Text === "Apple") {
		return (
			<Fragment>
				<a href={`https://${data?.URL}`} target="blank">
					<button className="button-g btn-apple">
						<span className="btn-title">Apple Store</span>
					</button>
				</a>
			</Fragment>
		);
	}
	if (data?.Text === "Learn more") {
		return (
			<Fragment>
				<a href={`${data?.URL}`}>
					<button className="learn-more-button">
						<span className="learn-more">{data.Text}</span>
					</button>
				</a>
			</Fragment>
		);
	}
};

export default Button;
