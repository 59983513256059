import React, { Fragment } from "react";
import Accordion from "../content-types/Accordion";
import "./HelpPageSection2.scss";

const HelpPageSection2 = ({ data }) => {
	return (
		<Fragment>
			<div className="container py-5 px-4">
				<div className="row px-2">
					<div className="col-12 col-lg-8">
						{data?.attributes?.ContentTypes?.map((contentType, index) => {
							if (contentType?.__component === "content-type.list") {
								return (
									<Fragment key={index}>
										<div className="px-0 px-lg-4">
											<div className="pricing-ul-list ">
												<div className="row pricing-list">
													{contentType?.Item?.map((item, index) => {
														return (
															<Accordion  key={index}
																number={item?.Number}
																title={item?.Heading}
																text={item?.Text}
															/>
														);
													})}
												</div>
											</div>
										</div>
									</Fragment>
								);
							}
							return false
						})}
					</div>
					<div className="col-12 col-lg-4">
						<div className="container">
							<div className="row">
								{data?.attributes?.ContentTypes?.map((contentType, index) => {
									if (contentType?.__component === "content-type.title") {
										return (
											<Fragment key={index}>
												<div
													className="font-size-6 font-bold front-main-3 py-3 px-0"
													dangerouslySetInnerHTML={{
														__html: contentType?.Heading?.replace(
															/(?:\r\n|\r|\n)/g,
															"<br>"
														),
													}}
												></div>
											</Fragment>
										);
									}
									return false
								})}
								{data?.attributes?.ContentTypes?.map((contentType, index) => {
									if (contentType?.__component === "content-type.item") {
										return (
											<Fragment key={index}>
												<div className="d-block resources-block my-4" style={{backgroundImage: "url(" + contentType?.IconURL + ")"}}>
													<div className="px-3 px-lg-0">
														<div className="resources-block-content">
															<div
																className="font-size-9 font-bold front-main-2 px-4"
																dangerouslySetInnerHTML={{
																	__html: contentType?.Heading?.replace(
																		/(?:\r\n|\r|\n)/g,
																		"<br>"
																	),
																}}
															></div>
															<div
																className="font-size-10 font-medium front-main-2 px-4"
																dangerouslySetInnerHTML={{
																	__html: contentType?.Text?.replace(
																		/(?:\r\n|\r|\n)/g,
																		"<br>"
																	),
																}}
															></div>
														</div>
													</div>
												</div>
											</Fragment>
										);
									}
									return false
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default HelpPageSection2;
