import React, { Fragment } from "react";
import Button from "../content-types/Button";
import "./PricingPageSection4.scss";

const PricingPageSection4 = ({ data }) => {
	return (
		<Fragment>
			<div className="back-sub-1">
				<div className="container pricing-section-4-container px-5 px-lg-3 py-5 mt-0 mt-lg-5">
					<div className="row pt-5">
						{data?.attributes?.ContentTypes?.map((contentType, index) => {
							if (contentType?.__component === "content-type.title") {
								return (
									<Fragment key={index}>
										<div className="col-12 col-lg-6 my-auto">
											<div
												className="font-bold front-main-2 pricing-list-heading pb-4"
												dangerouslySetInnerHTML={{
													__html: contentType?.Heading?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
											<div
												className="front-main-2 font-regular pricing-list-subheading"
												dangerouslySetInnerHTML={{
													__html: contentType?.SubHeading?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
										</div>
									</Fragment>
								);
							}
							return false
						})}
					</div>
					<div className="row">
						{data?.attributes?.ContentTypes?.map((contentType, index) => {
							if (contentType?.__component === "content-type.text") {
								return (
									<Fragment key={index}>
										<div className="col-12 col-lg-6">
											<div
												className="front-main-2 mt-3"
												dangerouslySetInnerHTML={{
													__html: contentType?.Text?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
										</div>
									</Fragment>
								);
							}
							return false
						})}
					</div>

					<div className="d-flex pt-5 button-container">
						{data?.attributes?.ContentTypes?.map((contentType, index) => {
							if (contentType?.__component === "content-type.buttons") {
								return (
									<Fragment key={index}>
										<Button data={contentType} />
									</Fragment>
								);
							}
							return false
						})}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default PricingPageSection4;
