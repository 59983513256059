// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/about-background-1.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero-image {
  background-color: var(--sub-3);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 450px;
}

.hero-section {
  height: 100%;
}

.hero-text {
  font-weight: bold;
}

.dot-and-line-text {
  margin-top: 30px;
  width: 400px;
}

.dot-and-line-text-2 {
  margin-top: 45px;
  width: 400px;
}

.dot-and-line-heading {
  margin-top: -50px;
  width: 400px;
}

.left {
  left: 0px;
}

.right {
  float: right;
  right: 0px;
}

.mobile-payment-image {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
}

.w-400 {
  width: 400px;
}`, "",{"version":3,"sources":["webpack://./src/pages/styles/AboutPage.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,4BAAA;EACA,sBAAA;EACA,iBAAA;AACF;;AACA;EACE,YAAA;AAEF;;AAAA;EACE,iBAAA;AAGF;;AACA;EACE,gBAAA;EACA,YAAA;AAEF;;AACA;EACE,gBAAA;EACA,YAAA;AAEF;;AACA;EACE,iBAAA;EACA,YAAA;AAEF;;AACA;EACE,SAAA;AAEF;;AACA;EACE,YAAA;EACA,UAAA;AAEF;;AAAA;EACE,yDAAA;EACA,4BAAA;EACA,sBAAA;EACA,aAAA;AAGF;;AAAA;EACE,YAAA;AAGF","sourcesContent":[".hero-image{\n  background-color: var(--sub-3);\n  background-repeat: no-repeat;\n  background-size: cover;\n  min-height: 450px;\n}\n.hero-section{\n  height: 100%;\n}\n.hero-text{\n  font-weight: bold;\n}\n\n\n.dot-and-line-text{\n  margin-top: 30px;\n  width: 400px;\n}\n\n.dot-and-line-text-2{\n  margin-top: 45px;\n  width: 400px;\n}\n\n.dot-and-line-heading{\n  margin-top: -50px;\n  width: 400px;\n}\n\n.left{\n  left: 0px;\n}\n\n.right{\n  float: right;\n  right: 0px;\n}\n.mobile-payment-image{\n  background-image: url(../../assets/images/about-background-1.png);\n  background-repeat: no-repeat;\n  background-size: cover;\n  height: 600px;\n}\n\n.w-400{\n  width: 400px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
