import React, { Fragment, useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Loader from "../components/Loader";
import CmsService from "../services/CMSService";
import "./styles/ArticlesPage.scss";
import moment from 'moment'
import { marked } from 'marked';
import SEO from "../components/content-types/SEO";

const ArticleIdPage = () => {
    const params = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        params.id && getData(params.id);
    },[params.id]);

    const getData = async (id) => {
        const service = new CmsService();
        const response = await service.getArticlePageByUidTag(id);
        if (response?.data) {
            setData(response?.data);
            setIsLoading(false);
        }
    }

    return (
        <Fragment>
            {data?.attributes?.SEOList?.SEOItem?.map((contentBlock, index) => {
                return <SEO key={index} data={contentBlock} />
            })}
            <Loader loading={isLoading} />
            <div style={{ paddingTop: "100px" }}>
                <div className="container">
                    <div className="row">
                        <div className="blog-container">
                            <span className="d-flex pt-4 blog-time">
                                <i>{moment(data?.attributes?.PublishDate).format("LL")}</i>
                                <ul className="p-0 ms-3">
                                    <li> Blog</li>
                                </ul>
                            </span>
                            {data?.attributes?.ContentTypes?.map((contentType, index) => {
                                if (contentType?.__component === "content-type.title") {
                                    return (
                                        <Fragment key={index}>
                                            <h2
                                                className="d-block font-bold pb-3"
                                                dangerouslySetInnerHTML={{
													__html: marked.parse(contentType?.Heading)
                                                }}
                                            ></h2>
                                        </Fragment>
                                    );
                                }
                                return false;
                            })}
                        </div>
                    </div>
                </div>
                {data?.attributes?.ContentTypes?.map((contentType, index) => {
                    if (
                        contentType?.__component === "content-type.media" &&
                        contentType?.Type === "Image"
                    ) {
                        return (
                            <span key={index} className="d-block">
                                <img
                                    className="blog-page-image"
                                    src={`${contentType?.Media?.data?.attributes?.url}`}
                                    alt={`${contentType?.Media?.data?.attributes?.alternativeText}`}
                                />
                            </span>
                        );
                    }
                    return false;
                })}
                <div className="container">
                    <div className="row">
                        <div className="blog-container py-4">
                            {data?.attributes?.ContentTypes?.map((contentType, index) => {
                                if (contentType?.__component === "content-type.title") {
                                    return (
                                        <Fragment key={index}>
                                            <span
                                                className="d-block blog-page-subheading pb-4"
                                                dangerouslySetInnerHTML={{
                                                    __html: marked.parse(contentType?.SubHeading)
                                                }}
                                            ></span>
                                        </Fragment>
                                    );
                                }
                                if (contentType?.__component === "content-type.text") {
                                    return (
                                        <Fragment key={index}>
                                            <span
                                                className="d-block blog-page-text pb-5"
                                                dangerouslySetInnerHTML={{
                                                    __html: marked.parse(contentType?.Text)
                                                }}
                                            ></span>
                                        </Fragment>
                                    );
                                }
                                return false;
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ArticleIdPage;