// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resources-block {
  min-height: 177px;
  background-color: var(--sub-2);
  position: relative;
}

.resources-block-content {
  position: absolute;
  bottom: 10px;
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/content-blocks/HelpPageSection2.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;EACA,8BAAA;EACA,kBAAA;AACD;;AACA;EACC,kBAAA;EACA,YAAA;EACA,OAAA;AAED","sourcesContent":[".resources-block{\n\tmin-height: 177px;\n\tbackground-color: var(--sub-2);\n\tposition: relative;\n}\n.resources-block-content{\n\tposition: absolute;\n\tbottom: 10px;\n\tleft: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
