// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-title {
  font-size: var(--size-5);
  margin-bottom: -25px;
  padding-bottom: 5px;
}

.bottom-title {
  font-size: var(--size-2);
}

.top-right-title {
  font-size: var(--size-7);
}

.top-middle-title {
  font-size: var(--size-5);
  width: 40px;
}

.top-end-title {
  font-size: var(--size-1);
  padding: 0;
  padding-top: 0 !important;
}

.top-corner-title {
  margin-top: 24px;
  padding-top: 24px;
  width: 170px;
}

.header-1 {
  width: 88%;
  float: left;
}

.container-row {
  border-bottom: 2px var(--main-2) solid;
  padding-bottom: -20px !important;
}

.pricing-list {
  width: 100%;
  margin-left: 0px !important;
  color: var(--main-2);
  font-size: var(--size-9);
}

.pricing-list-items {
  list-style-position: outside;
  height: 80px;
}

.section3 {
  background-color: var(--main-hov-1) !important;
  display: flex;
  align-items: center;
}

.section3-header {
  font-size: var(--size-3);
  color: white;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/pages/styles/PricingPage.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;EACA,oBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,wBAAA;AACJ;;AAEA;EACI,wBAAA;AACJ;;AAEA;EACI,wBAAA;EACA,WAAA;AACJ;;AAEA;EACI,wBAAA;EACA,UAAA;EACA,yBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,iBAAA;EACA,YAAA;AACJ;;AAEA;EACI,UAAA;EACA,WAAA;AACJ;;AAEA;EACI,sCAAA;EACA,gCAAA;AACJ;;AAEA;EACI,WAAA;EACA,2BAAA;EACA,oBAAA;EACA,wBAAA;AACJ;;AAEA;EACI,4BAAA;EACA,YAAA;AACJ;;AAGA;EACI,8CAAA;EACA,aAAA;EACD,mBAAA;AAAH;;AAGA;EACI,wBAAA;EACA,YAAA;EACA,iBAAA;AAAJ","sourcesContent":[".top-title{\n    font-size: var(--size-5);\n    margin-bottom: -25px;\n    padding-bottom: 5px;\n}\n\n.bottom-title{\n    font-size: var(--size-2);\n}\n\n.top-right-title{\n    font-size: var(--size-7);\n}\n\n.top-middle-title{\n    font-size: var(--size-5);\n    width: 40px;\n}\n\n.top-end-title {\n    font-size: var(--size-1);\n    padding: 0;\n    padding-top: 0 !important;\n}\n\n.top-corner-title{\n    margin-top: 24px;\n    padding-top: 24px;\n    width: 170px;\n}\n\n.header-1{\n    width: 88%;\n    float: left;\n}\n\n.container-row{\n    border-bottom: 2px var(--main-2) solid;\n    padding-bottom: -20px !important;\n}\n\n.pricing-list{\n    width: 100%;\n    margin-left: 0px !important;\n    color: var(--main-2);\n    font-size: var(--size-9);\n}\n\n.pricing-list-items{\n    list-style-position: outside;\n    height: 80px;\n    \n}\n\n.section3{\n    background-color: var(--main-hov-1) !important;\n    display: flex;\n   align-items: center;\n}\n\n.section3-header{\n    font-size: var(--size-3) ;\n    color: white;\n    font-weight: bold;\n \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
