import React, { Fragment } from "react";
import Button from "../content-types/Button";
import Media from "../content-types/Media";

import Title from "../content-types/Title";
import "./Banner.scss";

const Banner = ({ data }) => {
	return (
		<Fragment>
			<div className="cover-image" style={{marginTop: "72px"}}>
				<div
					className="banner-container"
					style={{
						backgroundImage:
							"url(" +
							data?.attributes?.Background?.Image?.data?.attributes?.url +
							")",

						backgroundColor:
							data?.attributes?.Background?.Color === "Light"
								? "#ffffff"
								: "#ffffff",
					}}
				>
					<div className="container banner">
						<div className="row">
							{data?.attributes?.ContentTypes?.map((contentType, index) => {
								if (contentType?.__component === "content-type.title") {
									return (
										<Fragment key={index}>
											<div key={index} className="col-12 col-md-6 px-5 px-lg-0 position-relative">
												<Title data={contentType} />
												<div className="home-buttons">
													{data?.attributes?.ContentTypes?.map(
														(contentType, index) => {
															if (
																contentType?.__component ===
																"content-type.buttons"
															) {
																return (
																	<Fragment key={index}>
																		<Button data={contentType} />
																	</Fragment>
																);
															}
															return false
														}
													)}
												</div>
											</div>
										</Fragment>
									);
								}
								if (contentType?.__component === "content-type.media") {
									return (
										<Fragment key={index}>
											<div className="col-12 col-md-6 hero-image-container mx-auto">
												<Media data={contentType} />
											</div>
										</Fragment>
									);
								}
								return false
							})}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Banner;
