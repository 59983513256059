import React, { Fragment } from "react";
import "./PricingPageSection3.scss";

const PricingPageSection3 = ({ data }) => {
	return (
		<Fragment>
			<div className="back-main-3">
				<div className="container py-0 py-lg-5">
					<div className="row py-5">
						{data?.attributes?.ContentTypes?.map((contentType, index) => {
							if (contentType?.__component === "content-type.title") {
								return (
									<Fragment key={index}>
										<div className="col-12 col-lg-6 my-auto">
											<div
												className="font-bold front-sub-1 pricing-heading pb-4 "
												dangerouslySetInnerHTML={{
													__html: contentType?.Heading?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
											<div
												className="front-main-1 font-regular font-size-4 list-subheading"
												dangerouslySetInnerHTML={{
													__html: contentType?.SubHeading?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
										</div>
									</Fragment>
								);
							}
							if (contentType?.__component === "content-type.media") {
								return (
									<Fragment key={index}>
										<div className="col-12 col-lg-6 text-center">
											<img
												className="pricing-section-3-img"
												src={`${contentType?.Media?.data?.attributes?.url}`}
												alt={contentType?.Media?.data?.attributes?.Name}
											/>
										</div>
									</Fragment>
								);
							}
							return false
						})}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default PricingPageSection3;
