// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.is-mobile-button {
  background-color: var(--main-1);
  border-radius: 10px;
  position: fixed;
  top: 30px;
  right: 50%;
  z-index: 9998;
  padding: 10px 25px 14px 25px;
  text-decoration: none;
}

.is-mobile-button-text, .is-mobile-button-text:hover {
  text-decoration: none;
  color: var(--sub-1);
}

@media screen and (max-width: 1400px) {
  .is-mobile-button {
    right: 54%;
  }
}
@media screen and (max-width: 992px) {
  .is-mobile-button {
    top: 27px;
    right: 205px;
    padding: 6px 25px 9px 25px;
  }
}
@media screen and (max-width: 576px) {
  .is-mobile-button {
    right: 77px;
    padding: 6px 25px 10px 25px;
  }
}
@media screen and (max-width: 420px) {
  .is-mobile-button {
    padding: 0px;
    background-color: transparent;
    margin-top: 1px;
    line-height: 1;
  }
  .is-mobile-button-text, .is-mobile-button-text:hover {
    text-decoration: none;
    color: var(--main-1);
    max-width: 75px;
    text-align: right;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/content-types/MobileLink.scss"],"names":[],"mappings":"AACA;EACC,+BAAA;EACA,mBAAA;EACA,eAAA;EACA,SAAA;EACA,UAAA;EACA,aAAA;EACA,4BAAA;EACA,qBAAA;AAAD;;AAGA;EACC,qBAAA;EACA,mBAAA;AAAD;;AAGA;EACE;IACC,UAAA;EAAD;AACF;AAGA;EACG;IACC,SAAA;IACA,YAAA;IACA,0BAAA;EADF;AACF;AAIE;EACC;IACC,WAAA;IACA,2BAAA;EAFF;AACF;AAKE;EACC;IACC,YAAA;IACA,6BAAA;IACA,eAAA;IACA,cAAA;EAHF;EAKC;IACC,qBAAA;IACA,oBAAA;IACA,eAAA;IACA,iBAAA;EAHF;AACF","sourcesContent":["\n.is-mobile-button{\n\tbackground-color: var(--main-1);\n\tborder-radius: 10px;\n\tposition: fixed;\n\ttop: 30px;\n\tright: 50%;\n\tz-index: 9998;\n\tpadding: 10px 25px 14px 25px;\n\ttext-decoration: none;\n}\n\n.is-mobile-button-text, .is-mobile-button-text:hover{\n\ttext-decoration: none;\n\tcolor: var(--sub-1);\n}\n\n@media screen and (max-width: 1400px) {\n\t\t.is-mobile-button{\n\t\t\tright: 54%;\n\t\t}\n\t}\n\n@media screen and (max-width: 992px) {\n\t\t\t.is-mobile-button{\n\t\t\t\ttop: 27px;\n\t\t\t\tright: 205px;\n\t\t\t\tpadding: 6px 25px 9px 25px;\t\n\t\t\t}\n    }\n\n\t\t@media screen and (max-width: 576px) {\n\t\t\t.is-mobile-button{\n\t\t\t\tright: 77px;\n\t\t\t\tpadding: 6px 25px 10px 25px;\n\t\t\t}\n\t\t}\n\n\t\t@media screen and (max-width: 420px) {\n\t\t\t.is-mobile-button{\n\t\t\t\tpadding: 0px;\n\t\t\t\tbackground-color: transparent;\n\t\t\t\tmargin-top: 1px;\n\t\t\t\tline-height: 1;\n\t\t\t}\n\t\t\t.is-mobile-button-text, .is-mobile-button-text:hover{\n\t\t\t\ttext-decoration: none;\n\t\t\t\tcolor: var(--main-1);\n\t\t\t\tmax-width: 75px;\n\t\t\t\ttext-align: right;\n\t\t\t}\n\t\t}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
