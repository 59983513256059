import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Banner from "../components/content-blocks/Banner";
import Loader from "../components/Loader";
import CmsService from "../services/CMSService";
import "./styles/PricingPage.scss";
import "./styles/ArticlesPage.scss";
import moment from 'moment'
import Image from "../components/content-types/image";
import SEO from "../components/content-types/SEO";
import { marked } from 'marked';

const ArticlesPage = ({ banner }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [showMore, setShowMore] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const service = new CmsService();
        const response = await service.getArticlePages();
        if (response?.data) {
            setData(response?.data);
            setIsLoading(false);
        }
    }

    const toggleShow = () => {
        setShowMore(!showMore)
    }

    const sort = [...data].sort((a, b) =>
        a.name > b.name ? -1 : 1,
    );

    return (
        <Fragment>
            {banner?.SEOList?.SEOItem?.map((contentBlock, index) => {
                return <SEO key={index} data={contentBlock} />
            })}
            <Loader loading={isLoading} />
            <div>
                {banner?.ContentBlocks?.data?.map((contentBlock, index) => {
                    if (contentBlock?.attributes?.Type === "Banner") {
                        return <Banner key={index} data={contentBlock} />;
                    }
                    return false;
                })}
                <div style={{backgroundColor: "#EEEEEE"}}>
                    <div className="container">
                        <div className="row pb-5">
                            {!showMore && <>
                                {sort?.slice(0, 6).map((item,i) => {
                                    return (<Fragment key={i}>
                                        <div className="col-md-4 col-12">
                                            <Link key={item.id} to={`/article/${item?.attributes?.Slug}`}>
                                                <div className="blog-container blog-card">
                                                    {item?.attributes?.ContentTypes?.map(
                                                        (contentType, index) => {
                                                            if (
                                                                (contentType?.__component ===
                                                                    "content-type.media") && (contentType?.Type === "Icon")
                                                            ) {
                                                                return (
                                                                    <Fragment key={index}>
                                                                        <span className="d-block">
                                                                            <img
                                                                                className="blog-image"
                                                                                src={`${contentType?.Media?.data?.attributes?.url}`}
                                                                                alt={`${contentType?.Media?.data?.attributes?.alternativeText}`}
                                                                            />
                                                                        </span>
                                                                    </Fragment>
                                                                );
                                                            }
                                                            return false;
                                                        }
                                                    )}
                                                    <div className="pt-1 pb-4 px-3">
                                                        <span className="d-block pt-2 blog-time">
                                                            <i>
                                                                {moment(item?.attributes?.PublishDate).format(
                                                                    "LL"
                                                                )}
                                                            </i>
                                                        </span>
                                                        {item?.attributes?.ContentTypes?.map(
                                                            (contentType, index) => {
                                                                if (
                                                                    contentType?.__component ===
                                                                    "content-type.title"
                                                                ) {
                                                                    return (
                                                                        <Fragment key={index}>
                                                                            <h4
                                                                                className="d-block font-bold"
                                                                                dangerouslySetInnerHTML={{
																					__html: marked.parse(contentType?.Heading)
                                                                                }}
                                                                            ></h4>
                                                                            <span
                                                                                className="d-block blog-subheading"
                                                                                dangerouslySetInnerHTML={{
																					__html: marked.parse(contentType?.SubHeading)
                                                                                }}
                                                                            ></span>
                                                                        </Fragment>
                                                                    );
                                                                }
                                                                return false;
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </Fragment>
                                    );
                                })}
                            </>}
                            {showMore && <>
                                {sort?.map((item, i) => {
                                    return (<Fragment key={i}>
                                        <div className="col-md-4 col-12">
                                            <Link key={item.id} to={`/article/${item?.attributes?.Slug}`}>
                                                <div className="blog-container blog-card">
                                                    {item?.attributes?.ContentTypes?.map(
                                                        (contentType, index) => {
                                                            if (
                                                                (contentType?.__component ===
                                                                    "content-type.media") && (contentType?.Type === "Icon")
                                                            ) {
                                                                return (
                                                                    <Fragment key={index}>
                                                                        <span className="d-block">
                                                                            <img
                                                                                className="blog-image"
                                                                                src={`${contentType?.Media?.data?.attributes?.url}`}
                                                                                alt={`${contentType?.Media?.data?.attributes?.alternativeText}`}
                                                                            />
                                                                        </span>
                                                                    </Fragment>
                                                                );
                                                            }
                                                            return false;
                                                        }
                                                    )}
                                                    <div className="pt-1 pb-4 px-3">
                                                        <span className="d-block pt-2 blog-time">
                                                            <i>
                                                                {moment(item?.attributes?.PublishDate).format(
                                                                    "LL"
                                                                )}
                                                            </i>
                                                        </span>
                                                        {item?.attributes?.ContentTypes?.map(
                                                            (contentType, index) => {
                                                                if (
                                                                    contentType?.__component ===
                                                                    "content-type.title"
                                                                ) {
                                                                    return (
                                                                        <Fragment key={index}>
                                                                            <h4
                                                                                className="d-block font-bold"
                                                                                dangerouslySetInnerHTML={{
																					__html: marked.parse(contentType?.Heading)
                                                                                }}
                                                                            ></h4>
                                                                            <span
                                                                                className="d-block blog-subheading"
                                                                                dangerouslySetInnerHTML={{
																					__html: marked.parse(contentType?.SubHeading)
                                                                                }}
                                                                            ></span>
                                                                        </Fragment>
                                                                    );
                                                                }
                                                                return false;
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </Fragment>
                                    );
                                })}
                            </>}
                            <div className="d-flex justify-content-end">
                                <button className="show-more-button" onClick={toggleShow}>{!showMore ? "Show more articles" : "Show less articles"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ArticlesPage;