import React, { Fragment } from "react";
import "./PricingPageSection2.scss";

const PricingPageSection2 = ({ data }) => {
	return (
		<Fragment>
			<div className="container py-5 px-5 px-lg-3">
				<div className="row position-relative my-5 py-4 ">
					{data?.attributes?.ContentTypes?.map((contentType, index) => {
						if (contentType?.__component === "content-type.title") {
							return (
								<Fragment key={index}>
									<div className="header-1">
										<div className="col-12 ">
											<div
												className="front-main-2 top-title "
												dangerouslySetInnerHTML={{
													__html: contentType?.SubHeading?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
											<div
												className="front-main-2 bottom-title font-bold"
												dangerouslySetInnerHTML={{
													__html: contentType?.Heading?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
										</div>
									</div>
									<hr className="container-row my-4 my-lg-0"></hr>
								</Fragment>
							);
						}
						return false;
					})}
					<div className="container pt-3 pt-lg-5">
						<div className="pricing-ul-list ">
							<div className="row pricing-list">
								{data?.attributes?.ContentTypes?.map((contentType, index) => {
									if (contentType?.__component === "content-type.list") {
										return contentType?.Item.map((item, index) => {
											return (
												<Fragment key={index}>
													<ul className="col-12 col-md-4 pb-5 pt-4">
														<li className="pricing-list-items mx-2 mx-lg-5">
															<h2
																className="pricing-list-title mx-auto"
																dangerouslySetInnerHTML={{
																	__html: item?.Heading?.replace(
																		/(?:\r\n|\r|\n)/g,
																		"<br>"
																	),
																}}
															></h2>
															<p
																className="my-3"
																dangerouslySetInnerHTML={{
																	__html: item?.Text?.replace(
																		/(?:\r\n|\r|\n)/g,
																		"<br>"
																	),
																}}
															></p>
														</li>
													</ul>
												</Fragment>
											);
										});
									}
									return false;
								})}
							</div>
						</div>

						{data?.attributes?.ContentTypes?.map((contentType, index) => {
							if (contentType?.__component === "content-type.buttons") {
								return (
									<Fragment key={index}>
										<div className="pricing-sub-text-container text-center mx-auto">
											<div
												className="back-main-1 front-sub-1 font-size-6 font-bold pricing-sub-text py-lg-3 px-lg-5 py-2 px-5"
												dangerouslySetInnerHTML={{
													__html: contentType?.Text?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
										</div>
									</Fragment>
								);
							}
							return false;
						})}

						{data?.attributes?.ContentTypes?.map((contentType, index) => {
							if (contentType?.__component === "content-type.text") {
								return (
									<Fragment key={index}>
										<div className="pricing-sub-text-container text-center mx-auto">
											<div
												className="front-main-1 font-size-6 font-bold py-lg-3 px-lg-5 py-2 px-5"
												dangerouslySetInnerHTML={{
													__html: contentType?.Text?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
										</div>
									</Fragment>
								);
							}
							return false;
						})}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default PricingPageSection2;
