// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
  float: right;
}

.pricing-list-heading {
  font-size: var(--size-2);
}

.pricing-list-subheading {
  font-size: var(--size-4);
  margin-bottom: 80px;
  margin-top: -130px;
}

.pricing-section-4-container {
  margin-bottom: 130px !important;
}

@media screen and (max-width: 992px) {
  .button-container {
    float: left;
  }
  .pricing-list-heading {
    font-size: var(--size-4);
  }
  .pricing-list-subheading {
    font-size: var(--size-8);
    margin-bottom: 60px;
    margin-top: -100px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/content-blocks/PricingPageSection4.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;AACD;;AAEA;EACC,wBAAA;AACD;;AAEA;EACC,wBAAA;EACA,mBAAA;EACA,kBAAA;AACD;;AAEA;EACC,+BAAA;AACD;;AAEA;EACC;IACC,WAAA;EACA;EAED;IACC,wBAAA;EAAA;EAGD;IACC,wBAAA;IACA,mBAAA;IACA,kBAAA;EADA;AACF","sourcesContent":[".button-container{\n\tfloat: right;\n}\n\n.pricing-list-heading{\n\tfont-size: var(--size-2);\n}\n\n.pricing-list-subheading{\n\tfont-size: var(--size-4);\n\tmargin-bottom: 80px;\n\tmargin-top: -130px;\n}\n\n.pricing-section-4-container{\n\tmargin-bottom: 130px !important;\n}\n\n@media screen and (max-width: 992px) {\n\t.button-container{\n\t\tfloat: left;\n\t}\n\n\t.pricing-list-heading{\n\t\tfont-size: var(--size-4);\n\t}\n\n\t.pricing-list-subheading{\n\t\tfont-size: var(--size-8);\n\t\tmargin-bottom: 60px;\n\t\tmargin-top: -100px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
