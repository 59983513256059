import React, { Fragment, useEffect, useState } from "react";
import "./ScrollList.scss";

const ScrollListMobile = ({ data }) => {
	const [length, setLength] = useState(0);
	const [scrollIndex, setScrollIndex] = useState(0);

	useEffect(() => {
		if (data?.length > 0) {
			setLength(data?.length);
		}
	}, [data]);

	return (
		<Fragment>
			<div className="front-sub-1 py-4">
				{length > 0 && (
					<div className="row">
						<div className="col-12 position-relative">
							<div className="text-center m-auto">
								{data[scrollIndex]?.Icon?.data?.attributes?.url && (
									<img
										className="scroll-image-mobile"
										src={`${data[scrollIndex]?.Icon?.data?.attributes?.url}`}
										alt={data[scrollIndex]?.Icon?.data?.attributes?.name}
									/>
								)}
							</div>
							
							<div className="scroll-text py-5">
							{data[scrollIndex]?.TextLeft && (
								<div
								className="pb-2"
									dangerouslySetInnerHTML={{
										__html: data[scrollIndex]?.TextLeft?.replace(
											/(?:\r\n|\r|\n)/g,
											"<br>"
										),
									}}
								></div>
							)}
							{data[scrollIndex]?.TextRight && (
								<div
								className="pb-2"
									dangerouslySetInnerHTML={{
										__html: data[scrollIndex]?.TextRight?.replace(
											/(?:\r\n|\r|\n)/g,
											"<br>"
										),
									}}
								></div>
								)}
								{data[scrollIndex]?.TextBottomLeft && (
											<div
											className="pb-2"
											dangerouslySetInnerHTML={{
												__html: data[scrollIndex]?.TextBottomLeft?.replace(
													/(?:\r\n|\r|\n)/g,
													"<br>"
												),
											}}
										></div>
										)}
										{data[scrollIndex]?.TextBottomRight && (
											<div
											className=""
											dangerouslySetInnerHTML={{
												__html: data[scrollIndex]?.TextBottomRight?.replace(
													/(?:\r\n|\r|\n)/g,
													"<br>"
												),
											}}
										></div>
										)}
							</div>

							<div className="button-container">
								<svg
									onClick={() =>
										setScrollIndex(
											scrollIndex > 0 ? scrollIndex - 1 : scrollIndex
										)
									}
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="16"
									fill="currentColor"
									className="bi bi-caret-left-fill me-2 caret"
									viewBox="0 0 16 16"
								>
									<path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
								</svg>
								<span className="scroll-index">{scrollIndex + 1}</span>
								<svg
									onClick={() =>
										setScrollIndex(
											scrollIndex < length - 1 ? scrollIndex + 1 : scrollIndex
										)
									}
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="16"
									fill="currentColor"
									className="bi bi-caret-right-fill ms-2 caret"
									viewBox="0 0 16 16"
								>
									<path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
								</svg>
							</div>
						</div>
					</div>
				)}
			</div>
		</Fragment>
	);
};

export default ScrollListMobile;
