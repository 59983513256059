import React, { Fragment } from "react";
import "./HomePageSection6.scss";

const HomePageSection6 = ({ data }) => {
	return (
		<Fragment>
			<div className="back-sub-1">
				<div className="container">
					<div className="row py-5 my-0 my-lg-5">
						<div className="col-12 py-5 my-auto px-5 px-lg-0">
							{data?.attributes?.ContentTypes?.map((contentType, index) => {
								if (contentType?.__component === "content-type.title") {
									return (
										<Fragment key={index}>
											<div
												className="font-bold front-main-1 list-heading pb-4"
												dangerouslySetInnerHTML={{
													__html: contentType?.Heading?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
											<div
												className="front-main-1 font-regular list-subheading"
												dangerouslySetInnerHTML={{
													__html: contentType?.SubHeading?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
										</Fragment>
									);
								}
								return false
							})}
						</div>

						{data?.attributes?.ContentTypes?.map((contentType, index) => {
							if (contentType?.__component === "content-type.list") {
								return contentType?.Item.map((item, index) => {
									return (
										<div
											key={index}
											className="col-12 col-md-6 mb-4 position-relative px-5 px-lg-0"
										>
											<div className="front-main-1 font-black font-size-6">
												0{item.Number}
											</div>
											<div className="front-main-2 mb-2 list-title font-black font-size-6">
												{item?.Heading}
											</div>
											<div className="front-main-2 font-size-10">
												{item?.Text}
											</div>
										</div>
									);
								});
							}
							return false
						})}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default HomePageSection6;
