import React, { Fragment } from "react";
import "./AboutPageSection5.scss";

const AboutPageSection5 = ({ data }) => {
	return (
		<Fragment>
			<div className="back-sub-1 py-5">
				<div className="container px-5 p-lg-4 mt-5 position-relative">
					{data?.attributes?.ContentTypes?.map((contentType, index) => {
						if (contentType?.__component === "content-type.title") {
							return (
								<Fragment key={index}>
									<div
										className="font-bold front-main-2 text-left about-section-5-heading"
										dangerouslySetInnerHTML={{
											__html: contentType?.Heading?.replace(
												/(?:\r\n|\r|\n)/g,
												"<br>"
											),
										}}
									></div>
									<div
										className="front-main-2 font-regular text-left about-section-5-subheading"
										dangerouslySetInnerHTML={{
											__html: contentType?.SubHeading?.replace(
												/(?:\r\n|\r|\n)/g,
												"<br>"
											),
										}}
									></div>
								</Fragment>
							);
						}
						return false
					})}
					<div className="row">
						{data?.attributes?.ContentTypes?.map((contentType, index) => {
							if (contentType?.__component === "content-type.list") {
								return contentType?.Item.map((item, index) => {
									return (
										<Fragment key={index}>
											<div className="col-lg-4 col-12 px-2 py-5 ">
												<div className="text-center my-5 px-4">
													<img
														className="img ico-img"
														src={`${item?.Icon?.data?.attributes?.url}`}
														alt={item.title}
													/>
												</div>
												<div className="front-main-2 font-size-8 font-black text-center px-0 px-lg-4">
													{item?.Heading}
												</div>
												<div className="front-main-2 font-size-9 text-center px-0 px-lg-4">
													{item?.Text}
												</div>
											</div>
										</Fragment>
									);
								});
							}
							return false
						})}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default AboutPageSection5;
