import React, { Fragment, useEffect, useState } from "react";
import "../../pages/styles/ContactPage.scss";
import SendGridService from "../../services/SendGridService";
import { useNavigate } from "react-router-dom";

const ContactPageSection1 = ({recaptchaKey, setNotificationMessage}) => {
    const navigate = useNavigate();
    const [form, setForm] = useState({
        firstName: "",
        lastName: "",
        contactNumber: "",
        message: "",
    })
    const [isValid, setIsValid] = useState( false )
    const [errorMessage, setErrorMessage] = useState("")

    const handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setForm({...form, [name]: value });
    }

    const onSubmit = async () => {
        let valid = false;
        if(form.message === ""){
            setErrorMessage("Please add your message.");
        }
        if(form.contactNumber === ""){
            setErrorMessage("Please tell us how to get back to you.");
        }
        if(form.lastName === ""){
            setErrorMessage("Please add your surname.");
        }
        if(form.firstName === ""){
            setErrorMessage("Please add your name.");
        }
        if(form.firstName !== "" && form.lastName !== "" && form.contactNumber !== "" && form.message !== ""){
            setErrorMessage("");
            setIsValid(true);
            valid = true;
        }
        if(valid){
            const sendGridService = new SendGridService();
            const response = await sendGridService.sendContactEmail(form.firstName, form.lastName, form.message, form.contactNumber);
            if(response.status === 200){
                setForm({
                    firstName: "",
                    lastName: "",
                    contactNumber: "",
                    message: "",
                });
                setNotificationMessage("Thank you for getting in touch with us, we will get back to you soon!");
                navigate("/");
                setTimeout(() => {
                    setNotificationMessage("");
                },3000)
            }
        }
    }

    return (
        <Fragment>
            <div className="row p-0 m-0">
                <div className="col-12 col-md-6 px-0">
                    <div className="p-2 p-md-4 front-main-2">
                        <h1 style={{ fontWeight: "bold" }}>
                            Hi <img className="wave-image" src="https://sadashpayuat.blob.core.windows.net/strapi-uploads-dev/assets/Wave_c09d271b8a.svg?width=98&height=98" alt=""></img> what's your name?
                        </h1>
                        <span className="d-inline-block contact-span">
                            Fill in the deets below to send us an email <br />
                            Old fashioned phone calls work too: &nbsp;
                            <a className="front-main-2" style={{ fontWeight: "bold", textDecoration: "none" }} href="tel:0872606600">087 260 6600</a>
                        </span>
                        <div className="py-2 py-md-4">
                            <input onChange={(event) => handleUserInput(event)} type="text" name="firstName" value={form.firstName} className="form-control input-form" required placeholder="Name" />
                            <input onChange={(event) => handleUserInput(event)} type="text" name="lastName" value={form.lastName} className="form-control input-form" required placeholder="Surname" />
                            <input onChange={(event) => handleUserInput(event)} type="number" name="contactNumber" value={form.contactNumber} className="form-control input-form" required placeholder="Contact number" />
                            <textarea onChange={(event) => handleUserInput(event)} type="text" name="message" value={form.message} style={{minHeight: "150px"}} className="form-control text-area-form" required placeholder="How can we help you get paid?"></textarea>
                            {errorMessage && <div className="panel panel-default">
                                <span style={{color: "red"}}>
                                    {errorMessage}
                                </span>
                            </div>}
                            <button className="submit-btn mt-3" onClick={() => {onSubmit()}}>Submit</button>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 px-0">
                    <Fragment>
                        <div className="back-main-2 p-2 p-md-4">
                            <ul className="ul-contact" style={{listStyleType: "none"}}>
                                <a target="blank" href="tel:0872606600" style={{ color: "#FFFFFF", fontWeight: "bold", textDecoration: "none", fontSize: "20px" }}>
                                    <li className="py-3 d-flex align-items-center"><img className="contact-icons" src="https://sadashpayuat.blob.core.windows.net/strapi-uploads-dev/assets/call_b8912cf47e.svg?width=72&height=72" alt="Phone" /> <span className="d-inline-block" style={{paddingLeft: "20px"}}>087 260 6600</span></li>
                                </a>
                                <a target="blank" href="mailto:support@dashpayglass.co.za" style={{ color: "#FFFFFF", fontWeight: "bold", textDecoration: "none", fontSize: "20px" }}>
                                    <li className="py-3 d-flex align-items-center"><img className="contact-icons" src="https://sadashpayuat.blob.core.windows.net/strapi-uploads-dev/assets/mail_9e9f5171f9.svg?width=72&height=72" alt="Mail" /> <span className="d-inline-block" style={{paddingLeft: "20px"}}>support@dashpayglass.co.za</span></li>
                                </a>
                                <a target="blank" href="https://goo.gl/maps/6ETaXTEenovLhKbo7" style={{ color: "#FFFFFF", fontWeight: "bold", textDecoration: "none", fontSize: "20px" }}>
                                    <li className="py-3 d-flex align-items-center"><img className="contact-icons" src="https://sadashpayuat.blob.core.windows.net/strapi-uploads-dev/assets/location_on_378959dab0.svg?width=72&height=72" alt="Location" /> <span className="d-inline-block" style={{ paddingLeft: "20px" }}>Unit 2 - 44 Saturn Crescent Linbro Business Park Sandton, 2065</span></li>
                                </a>
                            </ul>
                        </div>
                        <div className="p-0">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d896.0212443586291!2d28.10957732927223!3d-26.06347909424393!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e957282f38b92fb%3A0xe427750feaa699b0!2sLufil%20Packaging%20Linbro%20B%20Park!5e0!3m2!1sen!2sza!4v1680688870084!5m2!1sen!2sza" style={{border:"0", width: "100%", height: "400px"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </Fragment>
                    <Fragment>

                    </Fragment>
                </div>
            </div>
        </Fragment>
    );
};

export default ContactPageSection1;
