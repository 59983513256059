// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-title {
  margin-top: -10px;
}

.list-subheading {
  position: absolute;
  margin-top: -120px;
  font-size: var(--size-4);
}

.list-heading {
  font-size: var(--size-2);
}

@media screen and (max-width: 992px) {
  .list-heading {
    font-size: var(--size-4);
  }
  .list-subheading {
    position: absolute;
    margin-top: -80px;
    font-size: var(--size-8);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/content-blocks/HomePageSection6.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;AACD;;AAEA;EACC,kBAAA;EACA,kBAAA;EACA,wBAAA;AACD;;AAEA;EACC,wBAAA;AACD;;AAEA;EACC;IACC,wBAAA;EACA;EAED;IACC,kBAAA;IACA,iBAAA;IACA,wBAAA;EAAA;AACF","sourcesContent":[".list-title{\n\tmargin-top: -10px;\n}\n\n.list-subheading{\n\tposition: absolute;\n\tmargin-top: -120px;\n\tfont-size: var(--size-4);\n}\n\n.list-heading{\n\tfont-size: var(--size-2);\n}\n\n@media screen and (max-width: 992px) {\n\t.list-heading{\n\t\tfont-size: var(--size-4);\n\t}\n\t\n\t.list-subheading{\n\t\tposition: absolute;\n\t\tmargin-top: -80px;\n\t\tfont-size: var(--size-8);\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
