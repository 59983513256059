import React, { Fragment } from "react";
import { Helmet } from 'react-helmet';

const SEO = ({ data }) => {
	return (
		<Fragment>
			<Helmet > 
					{data?.Title && <title>
						{data?.Title}
					</title>}
					{data?.MetaName && data?.MetaContent && <meta name={data?.MetaName} content={data?.MetaContent} />}
				</Helmet>
		</Fragment>
	);
};

export default SEO;
