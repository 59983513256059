import React, { Fragment, useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import navbrand from "../assets/images/glassLogo.png";
import "./styles/Header.scss";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import MobileLink from "./content-types/MobileLink";
import ScrollToTop from "./ScrollToTopOnMount";

const Header = () => {
	const [expanded, setExpanded] = useState(false);

	return (
		<Fragment>
			<ScrollToTop>
			<Navbar
				className="header fixed-top"
				variant="light"
				expand="lg"
				expanded={expanded}
			>
				<Container>
					<Nav.Link as={Link} to="/" onClick={() => setExpanded(false)}>
						<img
							src={navbrand}
							width="auto"
							height="60"
							className="d-inline-block align-top"
							alt="logo"
						/>
					</Nav.Link>
					<Navbar.Toggle
						className="front-main-1"
						aria-controls="basic-navbar-nav"
						onClick={() => setExpanded(!expanded)}
					/>
					<Navbar.Collapse
						id="basic-navbar-nav float-right"
						className="px-2 py-4  nav-expanded"
					>
						<Nav
							className="me-auto justify-content-end"
							onClick={() => setExpanded(false)}
							style={{ width: "100%" }}
						>
							<Nav.Link
								as={Link}
								to="/"
								className="font-bold front-main-2 mx-4"
							>
								Home
							</Nav.Link>
							<Nav.Link
								as={Link}
								to="/about"
								className="font-bold front-main-2 mx-4"
							>
								How Glass Works
							</Nav.Link>
							<Nav.Link
								as={Link}
								to="/pricing"
								className="font-bold front-main-2 mx-4"
							>
								Pricing
							</Nav.Link>
							<Nav.Link
								as={Link}
								to="/help"
								className="font-bold front-main-2 mx-4"
							>
								Help and FAQs
							</Nav.Link>
							<Nav.Link
								as={Link}
								to="/contact-us"
								className="font-bold front-main-2 mx-4"
							>
								Contact Us
							</Nav.Link>
							<Nav.Link
								as={Link}
								to="/articles"
								className="font-bold front-main-2 mx-4"
							>
								Blog
							</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<MobileLink />
			</ScrollToTop>
		</Fragment>
	);
};

export default Header;
