import axios from "axios";
class CmsService {
	constructor() {
		this._config = {
			headers: {
				Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
				"Content-Type": "application/json",
			},
		};
		this._populatePage =
			"?populate[ContentBlocks][populate][Background][populate][0]=Image&populate[ContentBlocks][populate][ContentTypes][populate][0]=Media&populate[ContentBlocks][populate][ContentTypes][populate][1]=Item.Icon&populate[ContentBlocks][populate][ContentTypes][populate][2]=ScrollItem.Icon&populate[SEOList][populate][SEOItem][populate]";
		this._populateArticlePage =
			"?populate[SEOList][populate][SEOItem][populate]&populate[ContentTypes][populate][Media][populate]";
	}

	getPages = async () => {
		try {
			const data = await axios
				.get(
					`${process.env.REACT_APP_STRAPI_API_URL}/pages${this._populatePage}`,
					this._config
				)
				.then((response) => {
					response?.data?.data?.forEach((item) => {
						return item?.attributes?.ContentBlocks?.data?.sort(
							(a, b) => a.attributes.SortOrder - b.attributes.SortOrder
						);
					});
					return response.data;
				});
			return data;
		} catch (error) {
			console.log(error);
			return null;
		}
	};

	getArticlePages = async () => {
		try {
			const data = await axios
				.get(
					`${process.env.REACT_APP_STRAPI_API_URL}/article-pages${this._populateArticlePage}`,
					this._config
				)
				.then((response) => {
					response?.data?.data?.forEach((item) => {
						return item?.attributes?.ContentBlocks?.data?.sort(
							(a, b) => a.attributes.SortOrder - b.attributes.SortOrder
						);
					});
					response?.data?.data?.sort(
						(a, b) => new Date(b?.attributes?.PublishDate) - new Date(a?.attributes?.PublishDate)
					);
					return response.data;
				});
			return data;
		} catch (error) {
			console.log(error);
			return null;
		}
	};

	getArticlePageByUidTag = async (uid) => {
		try {
			const data = await axios

				.get(
					`${process.env.REACT_APP_STRAPI_API_URL}/article-pages${this._populateArticlePage}&filters[Slug][$eq]=${uid}`,
					this._config
				)
				.then((response) => {
					if (response.data.data.length > 0) {
						response?.data?.data[0]?.attributes?.ContentBlocks?.data?.sort(
							(a, b) => a.attributes.SortOrder - b.attributes.SortOrder
						);
						response.data.data = response?.data?.data[0];
						return response.data;
					}
					return null;
				});
			return data;
		} catch (error) {
			console.log(error);
			return null;
		}
	};
}
export default CmsService;
