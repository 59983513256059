import React, { Fragment } from "react";

import Banner from "../components/content-blocks/Banner";
import "./styles/PricingPage.scss";
import HelpPageSection2 from "../components/content-blocks/HelpPageSection2";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import SEO from "../components/content-types/SEO";
import { Helmet } from 'react-helmet';

const HelpPage = ({ data }) => {
	return (
		<Fragment>
			{data?.SEOList?.SEOItem?.map((contentBlock, index) => {
					return <SEO key={index} data={contentBlock} />
				})}

			{data?.ContentBlocks?.data?.map((contentBlock, index) => {
				if (contentBlock?.attributes?.Type === "Banner") {
					return <Banner key={index} data={contentBlock} />;
				}
				if (contentBlock?.attributes?.Type === "Section2") {
					return <HelpPageSection2 key={index} data={contentBlock} />;
				}
				return false;
			})}
		</Fragment>
	);
};

export default HelpPage;
