// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading {
  font-size: var(--size-2);
}

.disclaimer-text {
  font-size: var(--size-10);
  text-align: center;
}

.sub-text {
  font-size: var(--size-8);
}

@media screen and (max-width: 992px) {
  .heading {
    font-size: var(--size-4);
  }
  .sub-text {
    font-size: var(--size-10);
  }
  .disclaimer-text {
    font-size: var(--size-13);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/content-blocks/HomePageSection5.scss"],"names":[],"mappings":"AAAA;EACC,wBAAA;AACD;;AACA;EACC,yBAAA;EACA,kBAAA;AAED;;AACA;EACC,wBAAA;AAED;;AACA;EACC;IACC,wBAAA;EAEA;EACD;IACC,yBAAA;EACA;EAED;IACC,yBAAA;EAAA;AACF","sourcesContent":[".heading{\n\tfont-size: var(--size-2);\n}\n.disclaimer-text{\n\tfont-size: var(--size-10);\n\ttext-align: center;\n}\n\n.sub-text{\n\tfont-size: var(--size-8);\n}\n\n@media screen and (max-width: 992px) {\n\t.heading{\n\t\tfont-size: var(--size-4);\n\t}\n\n\t.sub-text{\n\t\tfont-size: var(--size-10);\n\t}\n\n\t.disclaimer-text{\n\t\tfont-size: var(--size-13);\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
