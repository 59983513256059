import axios from "axios";
class SendGridService {
	constructor() {
		this._config = {
			headers: {
				Authorization: `Bearer ${process.env.REACT_APP_SENDGRID_API_TOKEN}`,
				"Content-Type": "application/json",
			},
		};
	}


    sendContactEmail = async (firstName, lastName, message, contactNumber) => {
        if (!firstName || !lastName || !message || !contactNumber) {
            return null;
        }
		try {
			const data = await axios
				.post(
					`${process.env.REACT_APP_SENDGRID_URL}/contact-us?firstName=${firstName}&lastName=${lastName}&message=${message}&contactNumber=${contactNumber}`,
					this._config
				)
			return data;
		} catch (error) {
			return null;
		}
	};
}
export default SendGridService;
