import React, { Fragment, useState } from "react";
import "./MobileLink.scss";
import { isAndroid, isIOS, MobileView } from "react-device-detect";

const MobileLink = () => {
	const [expanded, setExpanded] = useState(false);

	// if (isIOS)
	// 	return (
	// 		<a
	// 			className="is-mobile-button"
	// 			href="https://www.google.com"
	// 			target="blank"
	// 		>
	// 			<div className="is-mobile-button-text">Download App</div>
	// 		</a>
	// 	);

	if (isAndroid)
		return (
			<a
				className="is-mobile-button"
				href="https://play.google.com/store/apps/details?id=za.co.synthesis.halo.mpos.dashpay"
				target="blank"
			>
				<div className="is-mobile-button-text">Download App</div>
			</a>
		);
};

export default MobileLink;
