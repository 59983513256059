// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-section-5-subheading {
  position: absolute;
  top: -25px;
  font-size: var(--size-4);
}

.about-section-5-heading {
  max-width: 750px;
  line-height: 1;
  font-size: var(--size-2);
}

@media screen and (max-width: 992px) {
  .about-section-5-subheading {
    position: absolute;
    font-size: var(--size-9);
  }
  .about-section-5-heading {
    font-size: var(--size-3);
  }
  .ico-img {
    max-width: 155px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/content-blocks/AboutPageSection5.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,UAAA;EACA,wBAAA;AACD;;AACA;EACC,gBAAA;EACA,cAAA;EACA,wBAAA;AAED;;AACA;EACC;IACC,kBAAA;IACA,wBAAA;EAEA;EACD;IACC,wBAAA;EACA;EAED;IACC,gBACA;EADA;AACF","sourcesContent":[".about-section-5-subheading{\n\tposition: absolute;\n\ttop: -25px;\n\tfont-size: var(--size-4);\n}\n.about-section-5-heading{\n\tmax-width: 750px;\n\tline-height: 1;\n\tfont-size: var(--size-2);\n}\n\n@media screen and (max-width: 992px) {\n\t.about-section-5-subheading{\n\t\tposition: absolute;\n\t\tfont-size: var(--size-9);\n\t}\n\n\t.about-section-5-heading{\n\t\tfont-size: var(--size-3);\n\t}\n\n\t.ico-img{\n\t\tmax-width: \n\t\t155px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
