import React, { Fragment } from "react";
import "./PrivacyPageSection1.scss";

const TermsPageSection1 = ({ data }) => {
	return (
		<Fragment>
			<div className="container my-5 py-5 px-5 px-lg-3">
				<div className="row position-relative my-5 py-4 ">
					{data?.attributes?.ContentTypes?.map((contentType, index) => {
						if (contentType?.__component === "content-type.title") {
							return (
								<Fragment key={index}>
									<div className="header-1">
										<div className="col-12 ">
											<div
												className="front-main-2 top-title "
												dangerouslySetInnerHTML={{
													__html: contentType?.SubHeading?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
											<div
												className="front-main-2 bottom-title font-bold"
												dangerouslySetInnerHTML={{
													__html: contentType?.Heading?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
										</div>
									</div>
									<hr className="container-row my-4 my-lg-0"></hr>
								</Fragment>
							);
						}
						if (contentType?.__component === "content-type.text") {
							return (
								<Fragment key={index}>
									<div
										className="front-main-2 my-5 section-text"
										dangerouslySetInnerHTML={{
											__html: contentType?.Text?.replace(
												/(?:\r\n|\r|\n)/g,
												"<br>"
											),
										}}
									></div>
								</Fragment>
							);
						}
						if (contentType?.__component === "content-type.item") {
							return (
								<Fragment key={index}>
									<div className="top-right-title-position">
										<div
											className="front-main-1 top-right-title font-bold"
											dangerouslySetInnerHTML={{
												__html: contentType?.Heading?.replace(
													/(?:\r\n|\r|\n)/g,
													"<br>"
												),
											}}
										></div>
										<div className="d-flex">
											<div
												className="front-main-1 top-middle-title font-bold text-right"
												dangerouslySetInnerHTML={{
													__html: contentType?.SubHeading?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
											<div
												className="front-main-1 top-end-title font-bold"
												dangerouslySetInnerHTML={{
													__html: contentType?.Text?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
										</div>
									</div>
								</Fragment>
							);
						}
						return false
					})}
					<div className="container pt-3 pt-lg-5">
						<div className="pricing-ul-list ">
							<div className="row pricing-list">
								{data?.attributes?.ContentTypes?.map((contentType, index) => {
									if (contentType?.__component === "content-type.list") {
										return contentType?.Item.map((item, index) => {
											return (
												<Fragment key={index}>
													{item.Heading && <div className="list-title py-4">
														{item?.Heading}
													</div>}
													<div
														dangerouslySetInnerHTML={{
															__html: item?.Text?.replace(
																/(?:\r\n|\r|\n)/g,
																"<br>"
															),
														}}
													></div>
												</Fragment>
											);
										});
									}
									return false
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default TermsPageSection1;
