// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading {
  line-height: 1;
  font-size: var(--size-2);
}

.subheading {
  position: absolute;
  top: -40px;
  font-size: var(--size-4);
}

@media screen and (max-width: 992px) {
  .heading {
    font-size: var(--size-3);
    margin-top: 100px;
  }
  .subheading {
    top: 70px;
    font-size: var(--size-6);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/content-blocks/HomePageSection3.scss"],"names":[],"mappings":"AAAA;EACC,cAAA;EACA,wBAAA;AACD;;AACA;EACC,kBAAA;EACA,UAAA;EACA,wBAAA;AAED;;AACA;EACC;IACC,wBAAA;IACA,iBAAA;EAEA;EAAD;IACC,SAAA;IACA,wBAAA;EAEA;AACF","sourcesContent":[".heading{\n\tline-height: 1;\n\tfont-size: var(--size-2);\n}\n.subheading{\n\tposition: absolute;\n\ttop: -40px;\n\tfont-size: var(--size-4);\n}\n\n@media screen and (max-width: 992px) {\n\t.heading{\n\t\tfont-size: var(--size-3);\n\t\tmargin-top: 100px;\n\t}\n\t.subheading{\n\t\ttop: 70px;\n\t\tfont-size: var(--size-6);\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
