import React, { Fragment, useEffect, useState } from "react";
import "./Media.scss";

const Video = ({ data }) => {
	return (
		<Fragment>
			{data?.Type === "Video" && 
			
			<iframe 
			className="video" 
			src={data?.MediaURL} 
			title="Tap that glass" 
			frameborder="0" 
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
			allowfullscreen>
			</iframe>
			}
		</Fragment>
	);
};

export default Video;
