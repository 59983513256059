// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.is-desktop {
  display: block;
}

.is-mobile {
  display: none;
}

.section-heading {
  font-size: var(--size-2);
}

.section-text {
  font-size: var(--size-8);
}

@media screen and (max-width: 992px) {
  .is-desktop {
    display: none;
  }
  .is-mobile {
    display: block !important;
  }
  .section-heading {
    font-size: var(--size-3);
  }
  .section-text {
    font-size: var(--size-10);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/content-blocks/AboutPageSection3.scss"],"names":[],"mappings":"AAAA;EACC,cAAA;AACD;;AAEA;EACC,aAAA;AACD;;AACA;EACC,wBAAA;AAED;;AACA;EACC,wBAAA;AAED;;AACA;EACC;IACC,aAAA;EAEA;EACD;IACC,yBAAA;EACA;EAED;IACC,wBAAA;EAAA;EAED;IACC,yBAAA;EAAA;AACF","sourcesContent":[".is-desktop{\n\tdisplay: block;\n}\n\n.is-mobile{\n\tdisplay: none;\n}\n.section-heading{\n\tfont-size: var(--size-2);\n}\n\n.section-text{\n\tfont-size: var(--size-8);\n}\n\n@media screen and (max-width: 992px) {\n\t.is-desktop{\n\t\tdisplay: none;\n\t}\n\t\n\t.is-mobile{\n\t\tdisplay: block !important;\n\t}\n\n\t.section-heading{\n\t\tfont-size: var(--size-3);\n\t}\n\t.section-text{\n\t\tfont-size: var(--size-10);\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
