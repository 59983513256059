// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mh-810 {
  height: 810px;
}

.section-2 {
  margin-top: -810px !important;
  overflow: hidden;
}

.image-1 {
  margin-top: 400px;
}

.image-2 {
  margin-top: 101px;
}

.image-3 {
  margin-top: -202px;
}

.fade-out {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-out.remove {
  transition-delay: 5000ms;
  opacity: 0;
}

.fade-in {
  opacity: 0;
  transition: opacity 250ms ease-in;
}

.fade-in.appear {
  transition-delay: 5000ms;
  opacity: 1;
}

@media screen and (max-width: 992px) {
  .section-2 {
    overflow-y: scroll;
  }
  .image-1 {
    margin-top: 0px;
  }
  .image-2 {
    margin-top: 0px;
  }
  .image-3 {
    margin-top: 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/content-blocks/AboutPageSection4.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;AACD;;AACA;EACC,6BAAA;EACA,gBAAA;AAED;;AACA;EACE,iBAAA;AAEF;;AACA;EACC,iBAAA;AAED;;AACA;EACC,kBAAA;AAED;;AACA;EACC,UAAA;EACA,iCAAA;AAED;;AACA;EACC,wBAAA;EACA,UAAA;AAED;;AACA;EACC,UAAA;EACA,iCAAA;AAED;;AACA;EACC,wBAAA;EACA,UAAA;AAED;;AACA;EACC;IACC,kBAAA;EAEA;EACD;IACC,eAAA;EACA;EAEF;IACC,eAAA;EAAC;EAGF;IACC,eAAA;EADC;AACF","sourcesContent":[".mh-810{\n\theight: 810px;\n}\n.section-2{\n\tmargin-top: -810px !important;\n\toverflow: hidden;\n}\n\n.image-1{\n\t\tmargin-top: 400px;\n}\n\n.image-2{\n\tmargin-top: 101px;\n}\n\n.image-3{\n\tmargin-top: -202px;\n}\n\n.fade-out{\n\topacity: 1;\n\ttransition: opacity 250ms ease-in;\n}\n\n.fade-out.remove {\n\ttransition-delay: 5000ms;\n\topacity: 0;\n}\n\n.fade-in{\n\topacity: 0;\n\ttransition: opacity 250ms ease-in;\n}\n\n.fade-in.appear {\n\ttransition-delay: 5000ms;\n\topacity: 1;\n}\n\n@media  screen and (max-width: 992px) {\n\t.section-2{\n\t\toverflow-y: scroll;\n\t}\n\n\t.image-1{\n\t\tmargin-top: 0px\n\t}\n\t\n.image-2{\n\tmargin-top: 0px;\n}\n\n.image-3{\n\tmargin-top: 0px;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
