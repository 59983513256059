import React, { Fragment } from "react";
import Button from "../content-types/Button";
import Image from "../content-types/image";
import "./HomePageSection3.scss";

const HomePageSection3 = ({ data }) => {
	return (
		<Fragment>
			<div className="back-main-1">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-6 my-auto px-5 pe-lg-5 position-relative">
							{data?.attributes?.ContentTypes?.map((contentType, index) => {
								if (contentType?.__component === "content-type.title") {
									return (
										<Fragment key={index}>
											<div
												className="font-bold front-sub-1 heading"
												dangerouslySetInnerHTML={{
													__html: contentType?.Heading?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
											<div
												className="front-sub-1 font-regular subheading"
												dangerouslySetInnerHTML={{
													__html: contentType?.SubHeading?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
										</Fragment>
									);
								}
								if (contentType?.__component === "content-type.text") {
									return (
										<Fragment key={index}>
											<div
												className="front-sub-1 py-4"
												dangerouslySetInnerHTML={{
													__html: contentType?.Text?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
										</Fragment>
									);
								}
								if (contentType?.__component === "content-type.buttons") {
									return (
										<Fragment key={index}>
											<Button data={contentType} />
										</Fragment>
									);
								}
								return false
							})}
						</div>
						<div className="col-12 col-md-6 mt-3 mt-lg-0 text-center is-desktop">
							{data?.attributes?.ContentTypes?.map((contentType, index) => {
								if (
									contentType?.__component === "content-type.media" &&
									contentType.id === 5
								) {
									return (
										<Fragment key={index}>
											<Image data={contentType} />
										</Fragment>
									);
								}
								return false
							})}
						</div>
						<div className="col-12 col-md-6 mt-3 mt-lg-0 text-center is-mobile pb-5">
							{data?.attributes?.ContentTypes?.map((contentType, index) => {
								if (
									contentType?.__component === "content-type.media" &&
									contentType.id === 16
								) {
									return (
										<Fragment key={index}>
											<Image data={contentType} />
										</Fragment>
									);
								}
								return false
							})}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default HomePageSection3;
