// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-title {
  color: var(--main-2);
  font-size: var(--size-6);
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/content-blocks/PrivacyPageSection1.scss"],"names":[],"mappings":"AAAA;EACC,oBAAA;EACA,wBAAA;EACA,iBAAA;AACD","sourcesContent":[".list-title{\n\tcolor: var(--main-2);\n\tfont-size: var(--size-6);\n\tfont-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
