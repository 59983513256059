import React, { Fragment, useEffect, useState } from "react";

const Image = ({ data }) => {
	return (
		<Fragment>
			<img
				className="img-fluid"
				src={`${data?.Media?.data?.attributes?.url}`}
				alt={data?.Media?.data?.attributes?.Name}
			/>
		</Fragment>
	);
};

export default Image;
