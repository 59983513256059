import React, { Fragment } from "react";
import PrivacyPageSection1 from "../components/content-blocks/PrivacyPageSection1";
import SEO from "../components/content-types/SEO";

const PrivacyPolicyPage = ({ data }) => {
	return (
		<Fragment>
			{data?.SEOList?.SEOItem?.map((contentBlock, index) => {
					return <SEO key={index} data={contentBlock} />
				})}

			{data?.ContentBlocks?.data?.map((contentBlock, index) => {
				if (contentBlock?.attributes?.Type === "Section1") {
					return <PrivacyPageSection1 key={index} data={contentBlock} />;
				}
				return false;
			})}
		</Fragment>
	);
};

export default PrivacyPolicyPage;
