import React, { Fragment } from "react";
import Banner from "../components/content-blocks/Banner";
import "./styles/PricingPage.scss";
import PricingPageSection2 from "../components/content-blocks/PricingPageSection2";
import PricingPageSection3 from "../components/content-blocks/PricingPageSection3";
import PricingPageSection4 from "../components/content-blocks/PricingPageSection4";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import SEO from "../components/content-types/SEO";


const PricingPage = ({ data }) => {
	return (
		<Fragment>
			{data?.SEOList?.SEOItem?.map((contentBlock, index) => {
					return <SEO key={index} data={contentBlock} />
				})}

			{data?.ContentBlocks?.data?.map((contentBlock, index) => {
				if (contentBlock?.attributes?.Type === "Banner") {
					return <Banner key={index} data={contentBlock} />;
				}
				if (contentBlock?.attributes?.Type === "Section2") {
					return <PricingPageSection2 key={index} data={contentBlock} />;
				}
				if (contentBlock?.attributes?.Type === "Section3") {
					return <PricingPageSection3 key={index} data={contentBlock} />;
				}
				if (contentBlock?.attributes?.Type === "Section4") {
					return <PricingPageSection4 key={index} data={contentBlock} />;
				}
				return false;
			})}
		</Fragment>
	);
};

export default PricingPage;
