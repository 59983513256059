import React, { Fragment } from "react";
import "./AboutPageSection2.scss";

import ScrollList from "../content-types/ScrollList";
import ScrollListMobile from "../content-types/ScrollListMobile";

const AboutPageSection2 = ({ data }) => {
	return (
		<Fragment>
			<div className="back-main-3">
				<div className="container-fluid px-5 scroll-block-container is-desktop">
					{data?.attributes?.ContentTypes?.map((contentType, index) => {
						if (contentType?.__component === "content-type.scroll-list") {
							return <ScrollList key={index} data={contentType?.ScrollItem} />;
						}
						return false;
					})}
				</div>
				<div className="container px-5 py-4 scroll-block-container is-mobile">
					{data?.attributes?.ContentTypes?.map((contentType, index) => {
						if (contentType?.__component === "content-type.scroll-list") {
							return (
								<ScrollListMobile key={index} data={contentType?.ScrollItem} />
							);
						}
						return false;
					})}
				</div>
			</div>
		</Fragment>
	);
};

export default AboutPageSection2;
