// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  width: 100%;
  z-index: 9999;
  background-color: var(--sub-1);
}

.navbar-toggler {
  border-color: rgba(0, 0, 0, 0);
}

.nav-expanded {
  height: 100% !important;
}

@media screen and (max-width: 992px) {
  .header {
    background-color: var(--sub-1);
    margin-top: 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/styles/Header.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,aAAA;EACA,8BAAA;AACD;;AAEA;EACC,8BAAA;AACD;;AAIA;EACC,uBAAA;AADD;;AAIA;EACI;IACI,8BAAA;IACJ,eAAA;EADF;AACF","sourcesContent":[".header{\n\twidth: 100%;\n\tz-index: 9999;\n\tbackground-color: var(--sub-1);\n}\n\n.navbar-toggler{\n\tborder-color: rgba(0,0,0,0);\n}\n\n\n\n.nav-expanded{\n\theight: 100% !important;\n}\n\n@media screen and (max-width: 992px) {\n    .header{\n        background-color: var(--sub-1);\n\t\t\t\tmargin-top: 0px;\n      }\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
