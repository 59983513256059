// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-icons {
  position: absolute;
  bottom: 0;
  right: 0;
}

.darkBackground {
  background-color: var(--sub-4);
  width: 100%;
}

.footer-container {
  padding: 0 !important;
  margin: 0 !important;
  max-width: 100% !important;
}

.footerBody {
  height: 351px;
}

.footerEnd {
  height: 53px;
  background-color: #3C3C3C;
  width: 100% !important;
  max-width: 100% !important;
  margin: 0px !important;
  align-items: center;
  color: white;
}

.footerItem {
  width: 280px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  color: white;
  text-align: left;
}

.footer-content {
  margin-left: 10% !important;
  margin-top: 75px;
  width: 280px;
}

.icon-row {
  margin-top: 255px !important;
  width: 180px;
  margin-left: 20% !important;
}

.icon-column {
  padding: 0;
  margin: 0;
}

.footer-links {
  color: var(--sub-1);
  list-style: none;
  font-weight: bold;
  text-decoration: none !important;
  padding-left: 0px;
}

li, a {
  text-decoration: none !important;
}

@media screen and (max-width: 992px) {
  .social-icons {
    position: relative;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/styles/Footer.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,SAAA;EACA,QAAA;AACJ;;AAEA;EACI,8BAAA;EACA,WAAA;AACJ;;AAEA;EACI,qBAAA;EACA,oBAAA;EACA,0BAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,YAAA;EACA,yBAAA;EACA,sBAAA;EACA,0BAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;AACJ;;AAEA;EACI,YAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;EACA,YAAA;EACA,gBAAA;AACJ;;AAEA;EACI,2BAAA;EACA,gBAAA;EACA,YAAA;AACJ;;AAEA;EACI,4BAAA;EACA,YAAA;EACA,2BAAA;AACJ;;AAEA;EACI,UAAA;EACA,SAAA;AACJ;;AAEA;EACI,mBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gCAAA;EACA,iBAAA;AACJ;;AAEA;EACI,gCAAA;AACJ;;AAEA;EACI;IACI,kBAAA;EACN;AACF","sourcesContent":[".social-icons{\n    position: absolute;\n    bottom: 0;\n    right: 0;\n}\n\n.darkBackground{\n    background-color: var(--sub-4);\n    width: 100%;   \n}\n\n.footer-container{\n    padding: 0 !important;\n    margin: 0 !important;\n    max-width: 100% !important;\n}\n\n.footerBody{\n    height: 351px;\n}\n\n.footerEnd{\n    height: 53px;\n    background-color: #3C3C3C;\n    width: 100% !important;\n    max-width: 100% !important;\n    margin: 0px !important;\n    align-items: center;\n    color: white;\n}\n\n.footerItem{\n    width: 280px;\n    height: 100%;\n    display: flex;\n    justify-content: flex-end;\n    color: white;\n    text-align: left;\n} \n\n.footer-content{\n    margin-left: 10% !important;\n    margin-top: 75px;\n    width: 280px;\n}\n\n.icon-row{\n    margin-top: 255px !important;\n    width: 180px;\n    margin-left: 20% !important;\n}\n\n.icon-column{\n    padding: 0;\n    margin: 0;\n}\n\n.footer-links{\n    color: var(--sub-1);\n    list-style: none;\n    font-weight: bold;\n    text-decoration: none !important;\n    padding-left: 0px;\n}\n\nli, a{\n    text-decoration: none !important;\n}\n\n@media screen and (max-width: 992px){\n    .social-icons{\n        position: relative;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
