// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll-block-container {
  padding: 150px 0px;
}

.is-desktop {
  display: block;
}

.is-mobile {
  display: none;
}

@media screen and (max-width: 992px) {
  .is-desktop {
    display: none;
  }
  .is-mobile {
    display: block !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/content-blocks/AboutPageSection2.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACC,cAAA;AACD;;AAEA;EACC,aAAA;AACD;;AAEA;EACC;IACC,aAAA;EACA;EAED;IACC,yBAAA;EAAA;AACF","sourcesContent":[".scroll-block-container{\n  padding: 150px 0px;\n}\n\n.is-desktop{\n\tdisplay: block;\n}\n\n.is-mobile{\n\tdisplay: none;\n}\n\n@media screen and (max-width: 992px) {\n\t.is-desktop{\n\t\tdisplay: none;\n\t}\n\t\n\t.is-mobile{\n\t\tdisplay: block !important;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
