import React, { Fragment } from "react";

import "./styles/Loader.scss";

const Loader = ({ loading }) => {
	return (
		<Fragment>
			{loading && (
				<div
					style={{
						position: "absolute",
						top: "0px",
						left: "0px",
						width: "100vw",
						height: "100vh",
						backgroundColor: "#C73CBD",
						zIndex: "9999",
					}}
				>
					<div className="lds-ellipsis center">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default Loader;
