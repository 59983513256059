import React, { Fragment } from "react";
import Video from "../content-types/Video";
import "./HomePageSection2.scss";

const HomePageSection2 = ({ data }) => {
	return (
		<Fragment>
			{data?.attributes?.ContentTypes?.map((contentType) => {
				if (contentType?.__component === "content-type.media") {
					return (
						<Fragment>
							<Video data={contentType} />
						</Fragment>
					);
				}
				return false
			})}
		</Fragment>
	);
};

export default HomePageSection2;
