import React, { Fragment } from "react";
import TermsPageSection1 from "../components/content-blocks/TermsPageSection1";
import SEO from "../components/content-types/SEO";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";

const TermsAndConditionsPage = ({ data }) => {
		return (
			<Fragment>
				{data?.SEOList?.SEOItem?.map((contentBlock, index) => {
					return <SEO key={index} data={contentBlock} />
				})}

				{data?.ContentBlocks?.data?.map((contentBlock, index) => {
					if (contentBlock?.attributes?.Type === "Section1") {
						return <TermsPageSection1 key={index} data={contentBlock} />;
					}
					return false;
				})}
			</Fragment>
		);
	};
	

export default TermsAndConditionsPage;
