import React, { Fragment } from "react";
import "./HomePageSection4.scss";

import Image from "../content-types/image";

const HomePageSection4 = ({ data }) => {
	return (
		<Fragment>
			<div className="container my-5 py-5 px-4">
				<div className="row">
					<div className="col-12 col-lg-3 is-desktop">
						{data?.attributes?.ContentTypes?.map((contentType, index) => {
							if (contentType?.__component === "content-type.media") {
								return (
									<Fragment key={index}>
										<Image data={contentType} />
									</Fragment>
								);
							}
							return false
						})}
					</div>

					<div className="col-12 col-lg-9">
						{data?.attributes?.ContentTypes?.map((contentType, index) => {
							if (contentType?.__component === "content-type.item") {
								return (
									<Fragment key={index}>
										<div className="list-sub-heading px-4">
											{contentType?.SubHeading}
										</div>

										<div className="list-heading px-4">
											{contentType?.Heading}
										</div>
										<div className="list-text px-4">{contentType?.Text}</div>
									</Fragment>
								);
							}
							return false
						})}
					</div>
					<div className="col-12 col-lg-3 is-mobile text-center">
						{data?.attributes?.ContentTypes?.map((contentType, index) => {
							if (contentType?.__component === "content-type.media") {
								return (
									<Fragment key={index}>
										<Image data={contentType} />
									</Fragment>
								);
							}
							return false
						})}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default HomePageSection4;
