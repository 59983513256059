import React, { Fragment, useEffect, useState } from "react";
import "./Media.scss";

const Media = ({ data }) => {
	return (
		<Fragment>
			<img
				className="banner-image"
				src={`${data?.Media?.data?.attributes?.url}`}
				alt={data?.Media?.data?.attributes?.Name}
			/>
		</Fragment>
	);
};

export default Media;
