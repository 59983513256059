import React, { Fragment, useEffect, useState } from "react";
import "./ScrollList.scss";

const ScrollList = ({ data }) => {
	const [length, setLength] = useState(0);
	const [scrollIndex, setScrollIndex] = useState(0);

	useEffect(() => {
		if (data?.length > 0) {
			setLength(data?.length);
		}
	}, [data]);
	return (
		<Fragment>
			<div className="front-sub-1 py-4">
				{length > 0 && (
					<div className="container">
						<div className="row">
							<div className="col-12 position-relative">
								<div className="position-absolute w-100 h-100">
									<svg
										onClick={() =>
											setScrollIndex(
												scrollIndex > 0 ? scrollIndex - 1 : scrollIndex
											)
										}
										xmlns="http://www.w3.org/2000/svg"
										width="32"
										height="32"
										fill="currentColor"
										className="bi bi-arrow-left-circle-fill left-arrow"
										viewBox="0 0 16 16"
									>
										<path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
									</svg>
									<svg
										onClick={() =>
											setScrollIndex(
												scrollIndex < length - 1 ? scrollIndex + 1 : scrollIndex
											)
										}
										xmlns="http://www.w3.org/2000/svg"
										width="32"
										height="32"
										fill="currentColor"
										className="bi bi-arrow-right-circle-fill right-arrow"
										viewBox="0 0 16 16"
									>
										<path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
									</svg>
								</div>
								<div>
									<div className="w-100 text-center mx-auto">
										{data[scrollIndex]?.Icon?.data?.attributes?.url && (
											<img
												className="scroll-image"
												src={`${data[scrollIndex]?.Icon?.data?.attributes?.url}`}
												alt={data[scrollIndex]?.Icon?.data?.attributes?.name}
											/>
										)}
									</div>
									<div className="scroll-text-desktop position-relative">
										{data[scrollIndex]?.TextLeft && (
											<div className="desktop-index-box-left py-4">
												<div
													className="scroll-text-desktop mx-auto px-4"
													dangerouslySetInnerHTML={{
														__html: data[scrollIndex]?.TextLeft?.replace(
															/(?:\r\n|\r|\n)/g,
															"<br>"
														),
													}}
												></div>
											</div>
										)}
										{data[scrollIndex]?.TextRight && (
											<div className="desktop-index-box py-4">
												<div
													className="scroll-text-desktop mx-auto px-4"
													dangerouslySetInnerHTML={{
														__html: data[scrollIndex]?.TextRight?.replace(
															/(?:\r\n|\r|\n)/g,
															"<br>"
														),
													}}
												></div>
											</div>
										)}
										{data[scrollIndex]?.TextBottomLeft && (
											<div className="desktop-index-box-bottom-left py-4">
												<div
													className="scroll-text-desktop mx-auto px-4"
													dangerouslySetInnerHTML={{
														__html: data[scrollIndex]?.TextBottomLeft?.replace(
															/(?:\r\n|\r|\n)/g,
															"<br>"
														),
													}}
												></div>
											</div>
										)}
										{data[scrollIndex]?.TextBottomRight && (
											<div className="desktop-index-box-bottom-right py-4">
												<div
													className="scroll-text-desktop mx-auto px-4"
													dangerouslySetInnerHTML={{
														__html: data[scrollIndex]?.TextBottomRight?.replace(
															/(?:\r\n|\r|\n)/g,
															"<br>"
														),
													}}
												></div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</Fragment>
	);
};

export default ScrollList;
