// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-text {
  font-size: var(--size-1);
  line-height: 96px;
  min-height: 580px;
  align-items: center;
  display: flex;
  margin-top: -50px;
}

@media screen and (max-width: 1400px) {
  .title-text {
    min-height: 0px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 1200px) {
  .title-text {
    min-height: 0px;
    margin-top: 50px;
    font-size: var(--size-2);
    line-height: 50px;
  }
}
@media screen and (max-width: 992px) {
  .title-text {
    min-height: 0px;
    font-size: var(--size-3);
    line-height: 40px;
  }
}
@media screen and (max-width: 768px) {
  .title-text {
    margin-top: 45px;
  }
}
@media screen and (max-width: 576px) {
  .title-text {
    margin-top: 0;
    margin-bottom: 80px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/content-types/Title.scss"],"names":[],"mappings":"AAAA;EACC,wBAAA;EACA,iBAAA;EACA,iBAAA;EACA,mBAAA;EACA,aAAA;EACA,iBAAA;AACD;;AAEA;EACC;IACC,eAAA;IACA,gBAAA;EACA;AACF;AACA;EACC;IACC,eAAA;IACA,gBAAA;IACA,wBAAA;IACA,iBAAA;EACA;AACF;AAEA;EACC;IACC,eAAA;IACA,wBAAA;IACA,iBAAA;EAAA;AACF;AAIA;EACC;IACC,gBAAA;EAFA;AACF;AAIA;EACC;IACC,aAAA;IACA,mBAAA;EAFA;AACF","sourcesContent":[".title-text{\n\tfont-size: var(--size-1);\n\tline-height: 96px;\n\tmin-height: 580px;\n\talign-items: center;\n\tdisplay: flex;\n\tmargin-top: -50px;\n}\n\n@media screen and (max-width: 1400px) {\n\t.title-text{\n\t\tmin-height: 0px;\n\t\tmargin-top: 20px;\n    }\n}\n@media screen and (max-width: 1200px) {\n\t.title-text{\n\t\tmin-height: 0px;\n\t\tmargin-top: 50px;\n\t\tfont-size: var(--size-2);\n\t\tline-height: 50px;\n    }\n}\n\n@media screen and (max-width: 992px) {\n\t.title-text{\n\t\tmin-height: 0px;\n\t\tfont-size: var(--size-3);\n\t\tline-height: 40px;\n\t\t// margin-top: 110px;\n    }\n}\n\n@media screen and (max-width: 768px) {\n\t.title-text{\n\t\tmargin-top: 45px;\n    }\n}\n@media screen and (max-width: 576px) {\n\t.title-text {\n\t\tmargin-top: 0;\n\t\tmargin-bottom: 80px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
