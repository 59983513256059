// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-container {
  color: #282c34;
}

.blog-card {
  margin: 50px 15px 0px;
  background-color: #ffffff;
  border-radius: 7px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1450980392);
}

.blog-image {
  width: 100%;
  height: 100%;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  aspect-ratio: 16/9;
}

.blog-page-image {
  width: 88%;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.blog-time {
  font-size: 12px;
  color: rgb(70, 70, 70);
}

.blog-subheading {
  font-size: 14px;
}

.blog-page-subheading {
  font-size: 24px;
  font-weight: bold;
}

.blog-page-text {
  font-size: 16px;
  font-weight: medium;
}

.show-more-button {
  border: 1px solid transparent;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1450980392);
  border-radius: 7px;
  background-color: #ffffff;
  text-decoration: none;
  color: var(--main-2);
  margin-top: 20px;
  margin-right: 15px;
  padding: 12px 20px;
  cursor: pointer;
  font-weight: bold;
  transition: 200ms;
}

.show-more-button:hover {
  border: 1px solid var(--main-2);
  transition: 200ms;
}

@media screen and (max-width: 992px) {
  .blog-page-image {
    width: 100%;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    aspect-ratio: auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/styles/ArticlesPage.scss"],"names":[],"mappings":"AAAA;EACC,cAAA;AACD;;AAEA;EACC,qBAAA;EACA,yBAAA;EACA,kBAAA;EACA,mDAAA;AACD;;AAEA;EACC,WAAA;EACA,YAAA;EACA,4BAAA;EACA,2BAAA;EACA,kBAAA;AACD;;AAEA;EACC,UAAA;EACA,4BAAA;EACA,+BAAA;AACD;;AAGA;EACC,eAAA;EACA,sBAAA;AAAD;;AAGA;EACC,eAAA;AAAD;;AAGA;EACC,eAAA;EACA,iBAAA;AAAD;;AAGA;EACC,eAAA;EACA,mBAAA;AAAD;;AAGA;EACC,6BAAA;EACA,mDAAA;EACA,kBAAA;EACA,yBAAA;EACA,qBAAA;EACA,oBAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;AAAD;;AAGA;EACC,+BAAA;EACA,iBAAA;AAAD;;AAGA;EACC;IACC,WAAA;IACA,4BAAA;IACA,+BAAA;IACA,kBAAA;EAAA;AACF","sourcesContent":[".blog-container{\n\tcolor: #282c34;\n}\n\n.blog-card{\n\tmargin: 50px 15px 0px;\n\tbackground-color: #ffffff;\n\tborder-radius: 7px;\n\tbox-shadow: 0px 3px 8px #00000025;\n}\n\n.blog-image{\n\twidth: 100%;\n\theight: 100%;\n\tborder-top-right-radius: 7px;\n\tborder-top-left-radius: 7px;\n\taspect-ratio: 16/9;\n}\n\n.blog-page-image{\n\twidth: 88%;\n\tborder-top-right-radius: 7px;\n\tborder-bottom-right-radius: 7px;\n\t// aspect-ratio: 16/4;\n}\n\n.blog-time{\n\tfont-size: 12px;\n\tcolor: rgb(70, 70, 70);\n}\n\n.blog-subheading{\n\tfont-size: 14px;\n}\n\n.blog-page-subheading{\n\tfont-size: 24px;\n\tfont-weight: bold;\n}\n\n.blog-page-text{\n\tfont-size: 16px;\n\tfont-weight: medium;\n}\n\n.show-more-button{\n\tborder: 1px solid transparent;\n\tbox-shadow: 0px 3px 8px #00000025;\n\tborder-radius: 7px;\n\tbackground-color: #ffffff;\n\ttext-decoration: none;\n\tcolor: var(--main-2);\n\tmargin-top: 20px;\n\tmargin-right: 15px;\n\tpadding: 12px 20px;\n\tcursor: pointer;\n\tfont-weight: bold;\n\ttransition: 200ms;\n}\n\n.show-more-button:hover{\n\tborder: 1px solid var(--main-2);\n\ttransition: 200ms;\n}\n\n@media screen and (max-width: 992px) {\n\t.blog-page-image{\n\t\twidth: 100%;\n\t\tborder-top-right-radius: 0px;\n\t\tborder-bottom-right-radius: 0px;\n\t\taspect-ratio: auto;\n\t}\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
