import React, { Fragment, useEffect, useState } from "react";
import "./Title.scss";

const Title = ({ data, textColor }) => {
	const hStyle = { color: textColor };
	return (
		<Fragment>
			<div
				className="front-main-1 title-text font-bold"
				style={hStyle}
				dangerouslySetInnerHTML={{
					__html: data?.Heading?.replace(/(?:\r\n|\r|\n)/g, "<br>"),
				}}
			></div>
			<div
				dangerouslySetInnerHTML={{
					__html: data?.SubHeading?.replace(/(?:\r\n|\r|\n)/g, "<br>"),
				}}
			></div>
		</Fragment>
	);
};

export default Title;
