import React, { Fragment } from "react";
import Image from "../content-types/image";
import "./HomePageSection5.scss";
import Button from "../content-types/Button";

const HomePageSection5 = ({ data }) => {
	return (
		<Fragment>
			<div className="back-main-3">
				<div className="container">
					<div className="row py-0 py-lg-5 px-4">
						<div className="col-12 px-4 py-0 py-lg-5 col-md-6 my-auto pe-0 pe-lg-5">
							{data?.attributes?.ContentTypes?.map((contentType, index) => {
								if (contentType?.__component === "content-type.title") {
									return (
										<Fragment key={index}>
											<div
												className="font-bold front-sub-1 heading pb-4"
												dangerouslySetInnerHTML={{
													__html: contentType?.Heading?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
											<div
												className="front-sub-1 font-regular font-size-4 subheading"
												dangerouslySetInnerHTML={{
													__html: contentType?.SubHeading?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
										</Fragment>
									);
								}
								if (contentType?.__component === "content-type.text") {
									return (
										<Fragment key={index}>
											<div
												className="front-sub-1 py-4 sub-text"
												dangerouslySetInnerHTML={{
													__html: contentType?.Text?.replace(
														/(?:\r\n|\r|\n)/g,
														"<br>"
													),
												}}
											></div>
										</Fragment>
									);
								}

								if (contentType?.__component === "content-type.buttons") {
									return (
										<Fragment key={index}>
											<Button data={contentType} />
										</Fragment>
									);
								}
								return false;
							})}
							{data?.attributes?.ContentTypes?.map((contentType, index) => {
								if (contentType?.__component === "content-type.list") {
									return (
										<Fragment key={index}>
											<div className="front-sub-1 pt-2 font-size-8 font-black">
												{contentType?.Item[0].SubHeading}
											</div>
										</Fragment>
									);
								}
								return false;
							})}
						</div>
						<div className="col-12 col-md-6 position-relative">
							{data?.attributes?.ContentTypes?.map((contentType, index) => {
								if (contentType?.__component === "content-type.list") {
									return (
										<Fragment key={index}>
											<div className="front-sub-1 py-4 text-center font-black font-size-8">
												{contentType?.Item[0].Heading}
											</div>
										</Fragment>
									);
								}
								if (contentType?.__component === "content-type.media") {
									return (
										<Fragment key={index}>
											<div className="text-center py-3 py-lg-0">
												<Image data={contentType} />
											</div>
										</Fragment>
									);
								}
								return false;
							})}
							{data?.attributes?.ContentTypes?.map((contentType, index) => {
								if (contentType?.__component === "content-type.list") {
									return (
										<Fragment key={index}>
											<div className="front-sub-1 pb-4 disclaimer-text">
												{contentType?.Item[0].Text}
											</div>
										</Fragment>
									);
								}
								return false;
							})}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default HomePageSection5;
