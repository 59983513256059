import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import MainRoutes from "./routes/Routes";
import Footer from "./components/Footer";
import "./App.scss";
import CmsService from "./services/CMSService";
import Loader from "./components/Loader";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
	gtmId: 'GTM-MZTZG9D'
}
TagManager.initialize(tagManagerArgs)

const App = () => {
	const service = new CmsService();
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState([]);
	const [recaptchaKey, setRecaptchaKey] = useState("");

	useEffect(() => {
		getData();
		setRecaptchaKey(process.env.REACT_APP_RECAPTCHA_SITE_KEY);
	}, []);

	const getData = async () => {
		setIsLoading(true);
		const response = await service.getPages();
		if (response?.data) {
			setData(response?.data);
			setIsLoading(false);
		}
	};

	return (
		<div className="App">
			<Loader loading={isLoading} />
			<Header />
			<MainRoutes data={data} recaptchaKey={recaptchaKey} />
			<Footer />
		</div>
	);
};

export default App;
