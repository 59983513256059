import React, { Fragment } from "react";
import Banner from "../components/content-blocks/Banner";
import "./styles/ContactPage.scss";

import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import SEO from "../components/content-types/SEO";
import ContactPageSection1 from "../components/content-blocks/ContactPageSection1";

const ContactPage = ({ data, recaptchaKey, setNotificationMessage}) => {
	return (
		<Fragment>
			{data?.SEOList?.SEOItem?.map((contentBlock, index) => {
					return <SEO key={index} data={contentBlock} />
				})}

			{data?.ContentBlocks?.data?.map((contentBlock, index) => {
				if (contentBlock?.attributes?.Type === "Banner") {
					return <Banner key={index} data={contentBlock} />;
				}
				if (contentBlock?.attributes?.Type === "Section1") {
					return <ContactPageSection1 key={index} data={contentBlock} recaptchaKey={recaptchaKey} setNotificationMessage={setNotificationMessage} />;
				}
				
				return false;
			})}
		</Fragment>
	);
};

export default ContactPage;
